import { createSlice } from "@reduxjs/toolkit";

const initialWorkflowsState = {
  listLoading: false,
  actionsLoading: false,
  stagesLoading: false,
  workflow: null,
  workflowStages: null,
  workflowStage: null,
  workflowFormActiveStage: undefined,
  workflows: null,
  workflowProceeded: false,
  workflowPreceded: false,
  workflowValidated: false,
  documentUploading: false,
  loadingFraudStatus: false,
  fraudStatus: undefined,
  loadingDedupeStatus: false,
  dedupeStatus: undefined,
  totalCount: 0,
  lastError: null,
  processAuthenticationResponse: null,
  integrationLoader: false,
  dedupeCriteriaLoading: false,
  dedupeCriteriaMatches: undefined,
  integrationData: undefined,
  checkList: undefined,
  approvingAuthority: undefined,
  documents: undefined,
  documentsTotalCount: 0,
  documentsLoading: undefined,
  trail: undefined,
  quickPanelOpened: false,
  authenticationList: null,
  exceptionsGroupByAuthentication: null,
  exceptionsLoader: false,
  checkListLoading: false,
  rejectedApplication: undefined,
  integrationList: undefined,
  discrepancies: null,
  discrepanciesLoader: false,
  exposureSheet: null,
  rectification: null,
  paymentPlan: null,
  documentDelete: false,
  customers: undefined,
  customersTotalCount: 0,
  customersPaging: null,
  previousDeviations: undefined,
  installmentPlan: null,
  workflowDiscrepancies: {},
  advices: {},
  transactionsLogs: [],
  transactionsLogsTotalCount: 0,
  creditSummary: {},
  draftedAuthentication: {},
  accountStatement: null,
  metaData: null,
  approvingAuthorityLite: undefined,
  latestStageAuthentication: undefined,
  latestStageExceptions: undefined,
  mailApprovalPasswordData: undefined,
  repricing: undefined,
  balloon: undefined,
  postCompletionFailure: undefined,
  manuallyDisbursement: undefined,
  repricedPortfolios: undefined,
  balloonPortfolios: undefined,
  repricedTotalCount: 0,
  repricingTotalCount: 0,
  masterEnquiryWorkflows: null,
  masterEnquiryTotalCount: 0
};
export const callTypes = {
  list: "list",
  action: "action",
  stages: "stages",
  checkList: "checkList"
};

export const slice = createSlice({
  name: "workflows",
  initialState: initialWorkflowsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.stagesLoading = false;
      } else {
        state.actionsLoading = false;
        state.stagesLoading = false;
        state.documentsLoading = false;
        state.documentUploading = false;
        state.checkListLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.stages) {
        state.stagesLoading = true;
      } else if (action.payload.callType === callTypes.checkList) {
        state.checkListLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    workflowsFetched: (state, action) => {
      const { totalCount, workflows } = action.payload;
      state.listLoading = false;
      state.workflows = workflows;
      state.workflowStages = null;
      state.totalCount = totalCount;
      state.workflow = null;
      state.error = null;
      state.approvingAuthority = null;
      state.documentsTotalCount = 0;
      state.documents = undefined;
      state.quickPanelOpened = false;
      state.exceptionsGroupByAuthentication = null;
      state.authenticationList = null;
      state.discrepancies = null;
      state.checkList = null;
      state.customersTotalCount = 0;
      state.customers = undefined;
      state.previousDeviations = undefined;
      state.installmentPlan = null;
      state.workflowDiscrepancies = {};
      state.advices = {};
    },
    workflowFetched: (state, action) => {
      state.actionsLoading = false;
      state.workflow = action.payload?.workflow || {};
      state.error = null;
      // state.exceptions = null;
      state.approvingAuthority = null;
      state.quickPanelOpened = false;
      // state.customersTotalCount = 0;
      // state.customers = undefined;
      state.previousDeviations = undefined;
      state.advices = {};
      state.workflowDiscrepancies = {};
      // state.stageAdvices = undefined;
      // state.stageAdvicesTotalCount = 0;
      // state.stageAdvicesPaging = null;
      // state.allAdvices = undefined;
      // state.allAdvicesTotalCount = 0;
      // state.allAdvicesPaging = null;
    },
    workflowStagesFetched: (state, action) => {
      state.stagesLoading = false;
      state.workflowStages = action.payload?.workflowStages || {};
      state.error = null;
      // state.exceptions = null;
    },
    workflowStageFetched: (state, action) => {
      state.stagesLoading = false;
      let currentWorkflowStages = { ...(state.workflowStages || {}) };
      currentWorkflowStages[action.payload?.workflowStage.stageCode] = {
        ...action.payload?.workflowStage
      };
      state.workflowStages = currentWorkflowStages;
      state.error = null;
    },
    createdWorkflowProduct: (state, action) => {
      state.workflow = null;
      state.workflowStages = null;
      state.actionsLoading = false;
      state.error = null;
      state.approvingAuthority = null;
      state.customersTotalCount = 0;
      state.customers = undefined;
      state.previousDeviations = undefined;
    },
    workflowValidated: (state, action) => {
      const { workflow } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.workflow = workflow || state.workflow;
    },
    workflowProceeded: (state, action) => {
      const { workflow } = action.payload || state.workflow;
      state.error = null;
      state.actionsLoading = false;
      state.workflowProceeded = true;
      state.workflow = workflow;
      state.approvingAuthority = null;
    },
    workflowPreceded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.workflowPreceded = true;
      state.approvingAuthority = null;
    },
    clearWorkflowList: (state, action) => {
      state.workflows = null;
      state.totalCount = 0;
    },
    exceptionCreated: (state, action) => {
      // state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    approveRejectException: (state, action) => {
      // state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    ShowExceptionsLoader: state => {
      state.exceptionsLoader = true;
    },
    exceptionsByAuthenticationFetched: (state, action) => {
      state.exceptionsGroupByAuthentication = action.payload;
      state.actionsLoading = false;
      state.exceptionsLoader = false;
      state.error = null;
    },
    exceptionDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    clearExceptions: (state, action) => {
      state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    authenticationResponse: (state, action) => {
      // state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    deletedGridRows: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    deleteDynamicGridRows: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    reassignmentResponse: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    assignmentResponse: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    documentUploadStarted: (state, action) => {
      state.error = null;
      state.documentUploading = true;
    },
    documentUploadCompleted: (state, action) => {
      state.error = null;
      state.documentUploading = false;
    },
    documentUploadFailed: (state, action) => {
      state.error = "Document upload failed.";
      state.documentUploading = false;
    },
    workflowFraudFetched: (state, action) => {
      state.error = null;
      state.fraudStatus = action.payload.fraudStatus;
    },
    workflowDedupeFetched: (state, action) => {
      state.error = null;
      state.dedupeStatus = action.payload.dedupeStatus;
    },
    loadingWorkflowIntegration: state => {
      state.error = null;
      state.integrationLoader = true;
    },
    loadingDedupeCriteria: state => {
      state.error = null;
      state.dedupeCriteriaLoading = true;
    },
    workflowIntegrated: (state, action) => {
      state.actionsLoading = false;
      const { integrationType, data } = action.payload;
      // For Integration of ATA
      state.error = null;
      state.integrationLoader = false;
      let payloadIntegrationData = { ...(state.integrationData || {}) };
      if (data?.length > 0 || data?.isMatch) {
        let objectIntegration = { [integrationType]: data };
        payloadIntegrationData = {
          ...payloadIntegrationData,
          ...objectIntegration
        };
      }
      state.integrationData = payloadIntegrationData;
    },
    DedupeCriteriaMathches: (state, action) => {
      const { dedupeCriteriaMatches } = action.payload;
      state.dedupeCriteriaLoading = false;
      state.error = null;
      state.dedupeCriteriaMatches = dedupeCriteriaMatches;
    },
    precheckData: (state, action) => {
      state.actionsLoading = false;
      const { integrationType, data } = action.payload;
      // console.log(action?.payload)
      // For Integration of ATA
      state.error = null;
      state.integrationLoader = false;
      let payloadIntegrationData = {};
      if (data?.length > 0) {
        let objectIntegration = { [integrationType]: data };
        payloadIntegrationData = {
          ...payloadIntegrationData,
          ...objectIntegration
        };
      }
      state.integrationList = payloadIntegrationData;
    },
    workflowIntegrationFailed: (state, action) => {
      state.error = "Failed to integrate workflow";
      state.integrationLoader = false;
    },
    workflowFormActiveStageUpdated: (state, action) => {
      state.workflowFormActiveStage = action.payload.activeStage;
      // state.exceptions = null;
    },
    checkListFetched: (state, action) => {
      const { checkList } = action.payload;
      state.checkList = checkList;
      state.actionsLoading = false;
      state.error = null;
    },
    clearCheckList: (state, action) => {
      state.checkList = undefined;
      state.integrationLoader = false;
      state.integrationData = undefined;
    },
    processCheckListResponse: (state, action) => {
      state.actionsLoading = false;
      state.checkListLoading = false;
      state.error = null;
      // state.checkList = undefined;
    },
    approvingAuthorityFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.approvingAuthority = action?.payload?.data;
    },
    allDocumentsFetched: (state, action) => {
      const { totalCount, documents } = action.payload;
      state.documentsLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.documents = documents;
      state.documentsTotalCount = totalCount;
      state.documentDelete = false;
    },
    documentsFetchStarted: (state, action) => {
      state.error = null;
      state.documentsLoading = true;
    },
    trailFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.trail = action?.payload?.data;
    },
    quickPanelOpened: (state, action) => {
      state.quickPanelOpened = true;
    },
    AuthenticationsByStageFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.authenticationList = action?.payload;
      state.exceptionsGroupByAuthentication = null;
    },
    rejectedApplication: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rejectedApplication = action?.payload;
    },

    discrepancyCreated: (state, action) => {
      // state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    approveRejectDiscrepancy: (state, action) => {
      // state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    showDiscrepancyLoader: state => {
      state.discrepanciesLoader = true;
    },
    discrepanciesFetched: (state, action) => {
      state.discrepancies = action.payload;
      state.actionsLoading = false;
      state.discrepanciesLoader = false;
      state.error = null;
    },
    discrepancyDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    clearDiscrepancies: (state, action) => {
      state.discrepancies = null;
      state.actionsLoading = false;
      state.error = null;
    },

    exposureSheetFetched: (state, action) => {
      const { exposureSheet } = action.payload;
      state.exposureSheet = exposureSheet;
      state.actionsLoading = false;
      state.error = null;
    },
    exposureSheetCreated: (state, action) => {
      const { exposureSheet } = action.payload;
      state.exposureSheet = exposureSheet;
      state.actionsLoading = false;
      state.error = null;
    },
    exposureSheetUpdated: (state, action) => {
      const { exposureSheet } = action.payload;
      state.exposureSheet = exposureSheet;
      state.actionsLoading = false;
      state.error = null;
    },
    rectificationCreated: state => {
      state.actionsLoading = false;
      state.error = null;
    },
    disbursementFileCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    paymentPlanFetched: (state, action) => {
      const { paymentPlan } = action.payload;
      state.paymentPlan = paymentPlan;
      state.actionsLoading = false;
      state.error = null;
    },
    installmentPlanFetched: (state, action) => {
      const { installmentPlan } = action.payload;
      state.installmentPlan = installmentPlan;
      state.actionsLoading = false;
      state.error = null;
    },
    documentDelete: state => {
      state.documentDelete = true;
      state.error = null;
      state.actionsLoading = false;
    },
    customerFetchedFromCNIC: state => {
      state.listLoading = false;
      state.error = null;
    },
    customerCreated: state => {
      // state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
    },
    customerUpdated: state => {
      state.actionsLoading = false;
      state.error = null;
    },
    customerDeleted: state => {
      state.actionsLoading = false;
      state.error = null;
    },
    allCustomersFetched: (state, action) => {
      let { totalCount, customers, paging } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.customers = customers;
      state.customersTotalCount = totalCount;
      state.customersPaging = paging;
    },
    previousDeviationsFetched: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.previousDeviations = action.payload;
    },
    dataCheckFileGenerated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    stageDiscrepancyCreated: (state, action) => {
      // state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    stageDiscrepancyUpdated: (state, action) => {
      // state.exceptions = null;
      state.actionsLoading = false;
      state.error = null;
    },
    allStageDiscrepanciesFetched: (state, action) => {
      let { totalCount, discrepancies, paging } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.workflowDiscrepancies["stageDiscrepancies"] = discrepancies;
      state.workflowDiscrepancies["stageTotalCount"] = totalCount;
      state.workflowDiscrepancies["stageDiscrepanciesPaging "] = paging;
    },
    allDiscrepanciesFetched: (state, action) => {
      let { totalCount, discrepancies, paging } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.workflowDiscrepancies["allDiscrepancies"] = discrepancies;
      state.workflowDiscrepancies["allTotalCount"] = totalCount;
      state.workflowDiscrepancies["allDiscrepanciesPaging"] = paging;
    },
    clearWorkflowDiscrepancy: state => {
      state.workflowDiscrepancies = {};
      state.error = null;
    },
    allStageAdvicesFetched: (state, action) => {
      let { totalCount, advices, paging } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.advices["stageAdvices"] = advices;
      state.advices["stageAdvicesTotalCount"] = totalCount;
      state.advices["stageAdvicesPaging"] = paging;
    },
    allAdvicesFetched: (state, action) => {
      let { totalCount, advices, paging } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.advices["allAdvices"] = advices;
      state.advices["allAdvicesTotalCount"] = totalCount;
      state.advices["allAdvicesPaging"] = paging;
    },
    clearAdvices: state => {
      state.advices = {};
      state.error = null;
    },
    adviceCreated: state => {
      // state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
    },
    adviceUpdated: state => {
      state.actionsLoading = false;
      state.error = null;
    },
    parseAccountStatementCompleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    updateFormData: (state, action) => {
      let {
        currentStageCode,
        updatedFields,
        updatedGridFields
      } = action.payload;

      let currentWorkflowStages = { ...(state.workflowStages || {}) };
      currentWorkflowStages[currentStageCode] = {
        ...(currentWorkflowStages[currentStageCode] || {}),
        fields: {
          ...(currentWorkflowStages[currentStageCode]?.["fields"] || {}),
          ...(updatedFields || {})
        },
        gridFields: {
          ...(currentWorkflowStages[currentStageCode]?.["gridFields"] || {}),
          ...(updatedGridFields || {})
        }
      };

      state.workflowStages = currentWorkflowStages;
    },
    transactionsLogsFetched: (state, action) => {
      const { totalCount, transactionsLogs } = action.payload;
      state.listLoading = false;
      state.transactionsLogs = transactionsLogs;
      state.transactionsLogsTotalCount = totalCount;
      state.error = null;
    },
    CreditSummaryFetched: (state, action) => {
      const { creditSummary } = action.payload;
      state.listLoading = false;
      state.creditSummary = creditSummary;
      state.error = null;
    },
    clearWorkflow: (state, action) => {
      state.workflow = null;
    },
    screeningChecksPerformed: (state, action) => {
      const screeningChecks = action.payload;
      state.screeningChecks = {
        ...(state.screeningChecks || {}),
        ...(screeningChecks || {})
      };
      state.listLoading = false;
      state.actionsLoading = false;
    },
    fetchAccountStatement: (state, action) => {
      const accountStatement = action.payload?.statements;
      state.actionsLoading = false;
      state.accountStatement = accountStatement;
      state.error = null;
    },
    fetchMetaData: (state, action) => {
      const metaData = action.payload?.data;
      state.actionsLoading = false;
      state.metaData = metaData;
      state.error = null;
    },
    clearStatementTransactions: state => {
      state.accountStatement = null;
      state.error = null;
    },
    updateAccountStatements: (state, action) => {
      state.actionsLoading = false;
      state.accountStatement = null;
      state.error = null;
    },
    proceedCheckListWithAction: (state, action) => {
      const { checkList } = action.payload;
      state.checkList = checkList;
      state.checkListLoading = false;
      state.workflow = action.payload?.checkList?.workflow || {};
    },
    mailApprovalPassword: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mailApprovalPasswordData = action?.payload?.data;
    },
    approvingAuthorityLite: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.approvingAuthorityLite = action?.payload?.data;
    },
    latestStageAuthentication: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.latestStageAuthentication = action?.payload;
      state.exceptionsGroupByAuthentication = null;
    },
    latestStageExceptions: (state, action) => {
      state.latestStageExceptions = action.payload;
      state.actionsLoading = false;
      state.exceptionsLoader = false;
      state.error = null;
    },
    clearAllWorkflowState: (state, action) => {
      state.workflowStages = null;
      state.workflow = null;
      state.approvingAuthorityLite = undefined;
      state.latestStageAuthentication = undefined;
      state.latestStageExceptions = undefined;
      state.mailApprovalPasswordData = undefined;
    },
    repricedPortfolios: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.repricedPortfolios = action?.payload?.data;
      state.repricedTotalCount = action?.payload?.totalCount;
    },
    repricing: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.repricing = action?.payload?.data;
      state.repricingTotalCount = action?.payload?.totalCount;
    },
    balloonPortfolios: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.balloonPortfolios = action?.payload?.data;
    },
    balloon: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.balloon = action?.payload?.data;
    },
    postCompletionFailure: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.postCompletionFailure = action?.payload;
    },
    manuallyDisbursement: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.manuallyDisbursement = action?.payload;
    },
    masterEnquiryWorkflowsFetched: (state, action) => {
      const { totalCount, workflows } = action.payload;
      state.listLoading = false;
      state.masterEnquiryWorkflows = workflows;
      state.workflowStages = null;
      state.totalCount = totalCount;
      state.workflow = null;
      state.error = null;
    },
    clearCustomers: state => {
      state.customersTotalCount = 0;
      state.customers = undefined;
    },
  }
});
