import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { ReactSelect } from "./ReactSelect";
import { errorNotification } from "../../../../_custom/_partials/notifications";
import {formatBytes, isValueEmpty} from "../../../_helpers";

export const GenericFileUpload = ({
  code,
  type,
  handleFileSelected,
  uploadingFile,
  acceptedFilesList,
  onDrop,
  groupList,
  handleChangeSelect,
  viewType,
    activeTab,
  acceptFileType,
  maxSizeOfFile,
  groupCode,
  uploadBtnSize,
  additionalInformation,
  version,
  documentOwner,
  isEligibleForReUploadMyDocument,
  isEligibleForReUploadOthersDocument,
  isDocumentGroupRequired,
    workflow
}) => {
  const { documentUploading, currentProduct, userAuth } = useSelector(
    state => ({
      documentUploading: state.workflows.documentUploading,
      currentProduct: state.products.productForEdit,
      userAuth: state.auth.user
    }),
    shallowEqual
  );
  const [filteredGroups, setFilteredGroups] = useState([]);
  let accept = acceptFileType;
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: accept,
    maxSize: maxSizeOfFile
  });

  useEffect(() => {
    if (groupList?.length) {
      let tempGroups = [];

      groupList.forEach(group => {
        const productCodes = (group?.values?.ProductCodes || "")?.split("!");

        if (productCodes.includes(currentProduct?.code)) {
          tempGroups.push({
            label: group.name,
            value: group.code
          });
        }
      });

      setFilteredGroups(tempGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupList]);

  useEffect(() => {
    fileRejections.forEach(({ file, errors }) => {
      const message = errors.map(e =>
        e?.code === "file-too-large"
          ? `File size should be less than ${formatBytes(maxSizeOfFile)}`
          : e?.message
      );

      errorNotification(`${file.path}: ${message}`, "fit-content");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  return (
    <>
      {type === "bulkUpload" ? (
        <>
          <div className="w-100 h-100px">
            <div
              {...getRootProps({
                className: `dropzone dropzone-default ${
                  acceptedFilesList?.length > 0
                    ? "dropzone-success"
                    : "dropzone-primary"
                } h-100px`
              })}
            >
              <input {...getInputProps()} />
              {acceptedFilesList?.length > 0 ? (
                <p>File(s) have been selected! Waiting Upload.</p>
              ) : (
                <p>
                  Drag 'n' drop some files here, or click to select files.
                  <br /> File size should not be greater than{" "}
                  {formatBytes(maxSizeOfFile)}.
                </p>
              )}
            </div>
          </div>
          {acceptedFilesList && acceptedFilesList?.length > 0 ? (
            <div
              className="mx-auto mt-4 import-modal-table"
              style={{ width: "100%" }}
            >
              <table className="mb-0 table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>File Name</th>
                    <th>
                      Group
                      {isDocumentGroupRequired ? (
                        <span className="text-danger font-weight-bold">*</span>
                      ) : (
                        ""
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(acceptedFilesList || []).map((file, index) => (
                    <>
                      <tr key={index - "accepted"}>
                        <td>{index + 1}.</td>
                        <td style={{ maxWidth: "35rem", width: "35rem" }}>
                          {file.path} - {formatBytes(file?.size)}
                        </td>
                        <td>
                          <div style={{ width: "240px" }}>
                            <ReactSelect
                              label={"Select Group"}
                              name={"group"}
                              className="w-100"
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 })
                              }}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              menuPlacement={"auto"}
                              onChange={option =>
                                handleChangeSelect(option, file?.name)
                              }
                              options={
                                filteredGroups?.length
                                  ? filteredGroups
                                  : (groupList || []).map(item => ({
                                      label: item.name,
                                      value: item.code
                                    }))
                              }
                              isRequired={isDocumentGroupRequired}
                              value={file?.name}
                              placeholder={"Select Group..."}
                              isSearchable
                              // disabled={!productData.length}
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <OverlayTrigger
            overlay={<Tooltip id="download-tooltip">Upload Document</Tooltip>}
          >
            {viewType === "module" ? (
              <>
                {(userAuth?.id === documentOwner
                  ? isEligibleForReUploadMyDocument
                  : isEligibleForReUploadOthersDocument) &&
                    ((isValueEmpty(workflow?.fields?.renewalProductCode) && isValueEmpty(workflow?.fields?.renewalWorkflowIdentifier)) || activeTab === "current")  &&  (
                  <label
                    htmlFor={`file-upload-${code}`}
                    className={`custom-file-upload btn  btn-outline-primary btn-sm ${
                      documentUploading && uploadingFile === code
                        ? "spinner spinner-right spinner-primary"
                        : ""
                    }`}
                    style={{ margin: "unset" }}
                  >
                    {version && version >= 1 ? "Re-Upload" : "Upload"}
                  </label>
                )}
              </>
            ) : (
              <>
                {documentUploading &&
                (uploadingFile === code || uploadingFile === groupCode) ? (
                  <div
                    className="spinner-border text-primary mr-2 my-1"
                    role="status"
                  />
                ) : (
                  <label
                    htmlFor={`file-upload-${code}`}
                    className={`custom-file-upload btn btn-icon btn-primary mb-0 btn-${uploadBtnSize ||
                      "sm"}`}
                  >
                    <i className="fas fa-file-upload text-white"></i>
                  </label>
                )}
              </>
            )}
          </OverlayTrigger>
          <input
            type="file"
            className="custom-file-input d-none"
            id={`file-upload-${code}`}
            accept={accept}
            onChange={e => {
              handleFileSelected(
                e.target.files,
                code,
                true,
                "single",
                e,
                groupCode,
                additionalInformation
              );
            }}
          />
        </>
      )}
    </>
  );
};
