/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, {useCallback, useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Dialog} from "../../../_partials/Dialog";
import {
    deleteDocument,
    deleteDocumentByVersion,
    downloadDocumentVersion,
    fetchDocuments,
    // parseAccountStatement,
    uploadDocument
} from "../../../../app/modules/Workflows/_redux/actions";
// import ReactQuill from 'react-quill';
import {fetchRecords} from "../../../../app/modules/MasterData/_redux/records/recordsActions";
import {DataGrid} from "../../../../_metronic/_partials/lists/DataGrid";
import {
    getHandlerTableChange,
    toAbsoluteUrl
} from "../../../../_metronic/_helpers";
import {
    DocumentActionFormatter,
    // DocumentCodeColumnFormatter,
    DocumentCreatedFormatter,
    DocumentGroupFormatter,
    DocumentLastUpdatedFormatter,
    DocumentNameFormatter
} from "../../grids/column-formatters";
import {GenericFileUpload} from "../../controls/forms/GenericFileUpload";
import {
    errorNotification,
    infoNotification,
    successNotification
} from "../../../../_custom/_partials/notifications";
import {extensionTypes} from "../../../../_custom/_helpers/staticFields";
import {v4 as uuidv4} from "uuid";
import {workflowStatus} from "../../../_helpers/enums";
import * as XLSX from "xlsx";
import {Thumbnails} from "./Thumbnails";
import {keyBy, merge, values} from "lodash";
import {createDictionary, toBase64} from "../../../../app/helper/functions";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
// import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import {Preview} from "./Preview";
import {Multiview} from "./Multiview";
import {formatBytes, isValueEmpty} from "../../../_helpers";
import {DocumentStageFormatter} from "../../grids/column-formatters/DocumentGroupFormatter";
import {appendFieldsWithDetails} from "../../../../app/modules/MasterDataConfiguration/_redux/fields/fieldsActions";
import {mimeTypes} from "../../grids/UIHelpers";
import {Tab, Nav} from "react-bootstrap";
import {CircularProgress} from "@material-ui/core";

export const DocumentManagement = ({
                                       show,
                                       modalHandler,
                                       isLoading,
                                       isWorkflowRoute,
                                       workflow,
                                       currentProduct,
                                       setImportedFields,
                                       acceptFileType,
                                       maxSizeOfFile,
                                       permissionsObject,
                                       fieldsValues,
                                       gridFieldsValues,
                                       onSubmit,
                                       isEditable,
                                       activeStageCode
                                   }) => {
    const [documentsList, setDocumentsList] = useState(undefined);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(null);
    const [acceptedFilesList, setAcceptedFilesList] = useState([]);
    const [dropZoneLoader, setDropZoneLoader] = useState(false);
    const [selectedGroupList, setSelectedGroupList] = useState({});
    const [viewingDocumentId, setViewingDocumentId] = useState(null);
    const [viewDocument, setViewDocument] = useState({});
    const [viewingDocumentExtension, setViewingDocumentExtension] = useState(
        null
    );
    const [isAllowUpload, setIsAllowUpload] = useState(false);
    const [viewType, setViewType] = useState("module");
    const [selectedDocuments, setSelectedDocument] = useState([]);
    const [documentLoader, setDocumentLoader] = useState(false);
    const [documentGroupsDictionary, setDocumentGroupsDictionary] = useState({});
    const [checkLisGroupsDictionary, setCheckListGroupsDictionary] = useState({});
    const [
        isEligibleForReUploadMyDocument,
        setIsEligibleForReUploadMyDocument
    ] = useState(false);
    const [
        isEligibleForReUploadOthersDocument,
        setIsEligibleForReUploadOthersDocument
    ] = useState(false);
    const [
        isEligibleForDocumentUpload,
        setIsEligibleForDocumentUpload
    ] = useState(false);
    const [openInTabOrWindow, setOpenInTabOrWindow] = useState("");
    const [documentVersion, setDocumentVersion] = useState();
    const [activeTab, setActiveTab] = useState("current");

    let DocumentTabs = [
        {
            key: "current",
            name: "Current Workflow Documents"
        },
        {
            key: "previous",
            name: "Previous Workflow Documents"
        }
    ];

    useEffect(() => {
        if (!show) {
            setActiveTab("current");
        }
    }, [show]);


    useEffect(() => {
        const hasViewPermission = isDocumentPermissionBased
            ? currentStage[0]?.documentPermissionGroups?.includes(
            "viewOthersDocument"
        ) && userAuth?.groupCodes?.includes("viewOthersDocument")
            : true;
        if (activeTab === "current") {
            let params = {
                identifier: workflow?.identifier, // Current Workflow Identifier
                pageNumber: 1,
                pageSize: 6,
                bucketCode: currentProduct?.code?.toLowerCase(),
                ...(isDocumentFetchStageWise ? {stageCode: activeStageCode} : {}),
                ...(hasViewPermission ? {} : {ownership: userAuth?.id})
            };
            dispatch(
                fetchDocuments(params)
            ).then((response) => {
                if (response?.status === 200) {
                    if (viewType === "module") {
                        const merged = merge(
                            keyBy(documentsList, "id"),
                            keyBy(response?.data?.items || [], "id")
                        );
                        setDocumentsList(values(merged));
                    } else {
                        setDocumentsList(allDocuments);
                    }
                }
            });
        } else if (activeTab === "previous") {
            let params = {
                identifier: workflow?.fields?.renewalWorkflowIdentifier,
                pageNumber: 1,
                pageSize: 6,
                bucketCode: currentProduct?.code?.toLowerCase(),
                ...(isDocumentFetchStageWise ? {stageCode: activeStageCode} : {}),
                ...(hasViewPermission ? {} : {ownership: userAuth?.id})
            };
            dispatch(
                fetchDocuments(params)
            ).then((response) => {
                if (response?.status === 200) {
                    // setPreviousDocumentsList(response?.data?.items || []);
                    if (viewType === "module") {
                        const merged = merge(
                            keyBy(documentsList, "id"),
                            keyBy(response?.data?.items || [], "id")
                        );
                        setDocumentsList(values(merged));
                    } else {
                        setDocumentsList(allDocuments);
                    }

                }
            });
        }
        setViewingDocumentId(null);
        setViewingDocumentExtension(null);
        if (viewType === "multiView") {
            setSelectedDocument(null);
            setViewType("module");
        }
        // setViewType("module");
// eslint-disable-next-line
    }, [activeTab]);

    let count = 0;
    const getGroupMapping = groupCode =>
        currentProduct?.importMappings?.find(data => data.groupCode === groupCode);
    // const modules = {
    //   toolbar: [
    //     [{'header': [1, 2, false]}],
    //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //     [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    //     ['clean']
    //   ],
    // };
    // const formats = [
    //   'header',
    //   'bold', 'italic', 'underline', 'strike', 'blockquote',
    //   'list', 'bullet', 'indent',
    //   'link', 'image'
    // ];

    const {
        allDocuments,
        totalCount,
        listLoading,
        allUsers,
        documentGroups,
        userAuth,
        documentDelete,
        checkLists,
        fieldsDictionary
    } = useSelector(
        state => ({
            allDocuments: state?.workflows?.documents || [],
            totalCount: state?.workflows?.documentsTotalCount || [],
            listLoading: state?.workflows?.documentsLoading,
            allUsers: state.users.allUsers,
            documentGroups: state.records?.DocumentGroups?.records,
            userAuth: state.auth.user,
            documentDelete: state?.workflows?.documentDelete,
            checkLists: state.products.productForEdit?.checkList,
            fieldsDictionary: state.fields?.fieldsDictionary
        }),
        shallowEqual
    );

    const [queryParams, setQueryParams] = useState({});
    const [isDocumentOpening, setIsDocumentOpening] = useState("");

    const dispatch = useDispatch();
    const currentStage = (currentProduct?.stages || []).filter(
        obj => obj.code === activeStageCode
    );
    const {permissions} = currentProduct;
    let parameters = currentProduct?.permissions?.dynamicParameters
        ? currentProduct?.permissions?.dynamicParameters
            ?.split("!")
            ?.filter(val => val !== "")
        : [];
    const isDocumentPermissionBased =
        parameters?.includes("IsDocumentPermissionBased") &&
        permissions?.IsDocumentPermissionBased === "true";
    const isDocumentGroupRequired =
        parameters?.includes("isDocumentGroupMandatory") &&
        permissions?.isDocumentGroupMandatory === "true";
    const isDocumentFetchStageWise =
        parameters?.includes("isDocumentFetchStageWise") &&
        permissions?.isDocumentFetchStageWise === "true";

    useEffect(() => {
        const stage = currentStage[0]?.documentPermissionGroups;
        const userGroup = userAuth?.groupCodes;

        const hasOwnReUploadPermission =
            stage?.includes("reUploadMyDocuments") &&
            userGroup?.includes("reUploadMyDocuments");
        const hasOthersReUploadPermission =
            stage?.includes("reUploadOthersDocument") &&
            userGroup?.includes("reUploadOthersDocument");
        const hasUploadPermission =
            stage?.includes("uploadDocumentsGroup") &&
            userGroup?.includes("uploadDocumentsGroup");

        if (isDocumentPermissionBased) {
            setIsEligibleForReUploadMyDocument(hasOwnReUploadPermission);
            setIsEligibleForReUploadOthersDocument(hasOthersReUploadPermission);
            setIsEligibleForDocumentUpload(hasUploadPermission);
        } else {
            setIsEligibleForReUploadOthersDocument(true);
            setIsEligibleForReUploadMyDocument(true);
            setIsEligibleForDocumentUpload(true);
        }
        // eslint-disable-next-line
    }, [currentProduct, currentStage, show]);


    useEffect(() => {
        if (workflow && currentProduct) {
            let parameters = currentProduct?.permissions?.dynamicParameters
                ? currentProduct?.permissions?.dynamicParameters
                    ?.split("!")
                    ?.filter(val => val !== "")
                : [];
            let isEligibleForCompletedWorkflowDocumentUpload = false;
            if (
                parameters?.includes("CompletedWorkflowDocumentUpload") &&
                currentProduct?.permissions?.CompletedWorkflowDocumentUpload
            ) {
                let groupsCodes = currentProduct?.permissions?.CompletedWorkflowDocumentUpload?.split(
                    "!"
                )?.filter(val => val !== "");
                (groupsCodes || []).forEach((value, index) => {
                    if (userAuth?.groupCodes?.includes(value)) {
                        isEligibleForCompletedWorkflowDocumentUpload = true;
                    }
                });
            }

            let isEligibleForEnquiryDocumentUpload = false;
            if (
                parameters?.includes("AllowDocumentUploadInEnquiry") &&
                currentProduct?.permissions?.AllowDocumentUploadInEnquiry
            ) {
                let groupsCodes = currentProduct?.permissions?.AllowDocumentUploadInEnquiry?.split(
                    "!"
                )?.filter(val => val !== "");
                (groupsCodes || []).forEach((value, index) => {
                    if (userAuth?.groupCodes?.includes(value)) {
                        isEligibleForEnquiryDocumentUpload = true;
                    }
                });
            }

            setIsAllowUpload(
                ((workflow?.status === workflowStatus.InProgress ||
                        workflow?.status === workflowStatus.AwaitingAuthentication ||
                        workflow?.status === workflowStatus.AwaitingPreChecksVerification) &&
                    (isWorkflowRoute || isEligibleForEnquiryDocumentUpload)) ||
                (isEligibleForCompletedWorkflowDocumentUpload &&
                    workflow?.status === workflowStatus.Completed)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProduct, workflow]);

    useEffect(() => {
        const hasViewPermission = isDocumentPermissionBased
            ? currentStage[0]?.documentPermissionGroups?.includes(
            "viewOthersDocument"
        ) && userAuth?.groupCodes?.includes("viewOthersDocument")
            : true;

        let params = {
            identifier: !isValueEmpty(workflow?.fields?.renewalWorkflowIdentifier) ? activeTab === "current" ? workflow?.identifier : workflow?.fields?.renewalWorkflowIdentifier : workflow?.identifier,
            pageNumber: 1,
            pageSize: 5,
            bucketCode: currentProduct?.code?.toLowerCase(),
            ...(isDocumentFetchStageWise ? {stageCode: activeStageCode} : {}),
            ...(hasViewPermission ? {} : {ownership: userAuth?.id})
        };

        if (viewType === "module") {
            params = {
                identifier: workflow?.identifier,
                pageNumber: 1,
                pageSize: 6,
                bucketCode: currentProduct?.code?.toLowerCase(),
                ...(isDocumentFetchStageWise ? {stageCode: activeStageCode} : {}),
                ...(hasViewPermission ? {} : {ownership: userAuth?.id})
            };
            // setDocumentsList([])
        }
        setQueryParams(params);
        dispatch(fetchDocuments(params)).then(response => {
            if (response?.status === 200) {
                if (viewType === "module") {
                    const merged = merge(
                        keyBy(documentsList, "id"),
                        keyBy(response?.data?.items || [], "id")
                    );
                    setDocumentsList(values(merged));
                } else {
                    setDocumentsList(allDocuments);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewType]);

    useEffect(() => {
        if (!show) {
            if (viewType === "module") {
                let params = {
                    identifier: workflow?.identifier,
                    pageNumber: 1,
                    pageSize: 6
                };
                setQueryParams(params);
            }
            setAcceptedFilesList(null);
            setSelectedGroupList({});
            setShowUploadModal(false);
            setDocumentsList(undefined);
            setViewingDocumentId(null);
            setSelectedDocument(null);
            setViewingDocumentExtension(null);
        } else {
            handleDocumentFetch();

            if (!documentGroups) {
                dispatch(
                    fetchRecords({
                        paginationOverride: true,
                        entityCode: "DocumentGroups"
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        if (documentGroups) {
            setDocumentGroupsDictionary(createDictionary(documentGroups, "code"));
        }
        if (checkLists) {
            setCheckListGroupsDictionary(createDictionary(checkLists, "code"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentGroups, checkLists]);

    const sizePerPageList = [
        {text: "5", value: 5},
        {text: "10", value: 10},
        {text: "25", value: 50},
        {text: "100", value: 100}
    ];


    useEffect(() => {
        const hasViewPermission = isDocumentPermissionBased
            ? currentStage[0]?.documentPermissionGroups?.includes(
            "viewOthersDocument"
        ) && userAuth?.groupCodes?.includes("viewOthersDocument")
            : true;
        let itemsNotInFields = (allDocuments || []).filter(
            item =>
                item?.additionalInformation &&
                !fieldsDictionary[item?.additionalInformation]
        );
        if (itemsNotInFields?.length > 0) {
            dispatch(
                appendFieldsWithDetails({
                    isDetailRequired: true,
                    fieldCodes: itemsNotInFields?.map(fld => fld?.additionalInformation)
                })
            );
        }
        // console.log({documentDelete})
        if (show || documentDelete) {
            dispatch(
                fetchDocuments({
                    identifier: !isValueEmpty(workflow?.fields?.renewalWorkflowIdentifier) ? activeTab === "current" ? workflow?.identifier : workflow?.fields?.renewalWorkflowIdentifier : workflow?.identifier,
                    pageNumber: queryParams?.pageNumber,
                    pageSize: queryParams?.pageSize,
                    bucketCode: currentProduct?.code?.toLowerCase(),
                    ...(isDocumentFetchStageWise ? { stageCode: activeStageCode } : {}),
                    ...(hasViewPermission ? {} : { ownership: userAuth?.id })
                })
            ).then(response => {
                if (response?.status === 200) {
                    if (viewType === "module" || viewType === "multiView") {
                        const merged = merge(
                            keyBy(documentsList, "id"),
                            keyBy(response?.data?.items || [], "id")
                        );
                        // console.log(response?.data?.items,values(merged), 'working with if')
                        setDocumentsList(values(merged));
                    } else {
                        // console.log('I am here');
                        setDocumentsList(allDocuments);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, documentDelete]);

    const onHide = () => {
        setViewType("module");
        modalHandler("document", false);
    };

    const handleDownload = async (data, versionCount, isView, activeTab) => {
        if (versionCount) {

            const params = {
                versionCount: versionCount,
                identifier: data?.id
            };
            const response = await dispatch(downloadDocumentVersion(params));

            if (response?.status === 200) {
                let downloadLink = response?.data;
                if (process.env.REACT_APP_FILE_STORAGE === "dell") {
                    const byteCharacters = atob(downloadLink?.data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    // Create a Blob from the binary data
                    const blob = new Blob([byteArray], {
                        type: "application/octet-stream"
                    });

                    if (isView) {
                        setDocumentVersion(versionCount);
                        setViewDocument(data);
                        setViewingDocumentId(URL.createObjectURL(blob));
                        setViewingDocumentExtension(downloadLink?.extension);
                    } else {
                        const link = document.createElement("a");
                        link.download = data?.code + "." + downloadLink?.extension;
                        link.href = URL.createObjectURL(blob);
                        link.target = "_blank";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                } else {
                    if (isView) {
                        setDocumentVersion(versionCount);
                        setViewDocument(data);
                        const documentExtension = downloadLink
                            ?.split("?")?.[0]
                            ?.split(".")
                            ?.reverse()?.[0];
                        setViewingDocumentId(downloadLink);
                        setViewingDocumentExtension(documentExtension);
                    } else {
                        const link = document.createElement("a");
                        link.download = data?.code;
                        link.href = downloadLink;
                        link.target = "_blank";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }
        }
    };


    const handleOpenDocumentInNewTab = async (data, versionCount) => {
        if (versionCount) {
            const {code, extension, id} = data;
            const params = {
                versionCount: versionCount,
                identifier: id || data?.documentId
            };
            setIsDocumentOpening(code);

            try {
                const response = await dispatch(downloadDocumentVersion(params));
                setIsDocumentOpening("");

                if (response?.status === 200) {
                    let downloadLink = response.data;
                    if (
                        ["xlsx", "docx", "ppt", "pptx"].includes(
                            extension || data?.viewExtension
                        ) &&
                        process.env.REACT_APP_FILE_STORAGE !== "dell"
                    ) {
                        downloadLink = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                            response?.data
                        )}`;
                    }

                    if (process.env.REACT_APP_FILE_STORAGE === "dell") {
                        const byteCharacters = atob(downloadLink?.data);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);

                        // Create a Blob from the binary data
                        const blob = new Blob([byteArray], {
                            type:
                                mimeTypes[downloadLink?.extension?.toLowerCase()] ||
                                "application/octet-stream" // default to octet-stream if unknown
                        });

                        downloadLink = URL.createObjectURL(blob);
                    }
                    window.open(downloadLink, "_blank");
                } else {
                    errorNotification("Unable to open document");
                }
            } catch (error) {
                console.error("Error opening document:", error);
            }
        }
    };

    const handleOpenDocumentInNewWindow = async (data, versionCount) => {
        if (versionCount) {
            const {code, extension, id} = data;
            const params = {
                versionCount: versionCount,
                identifier: id || data?.documentId
            };

            setIsDocumentOpening(code);

            try {
                const response = await dispatch(downloadDocumentVersion(params));
                setIsDocumentOpening("");

                if (response?.status === 200) {
                    let downloadLink = response.data;
                    if (
                        ["xlsx", "docx", "ppt", "pptx"].includes(
                            extension || data?.viewExtension
                        ) &&
                        process.env.REACT_APP_FILE_STORAGE !== "dell"
                    ) {
                        downloadLink = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                            response?.data
                        )}`;
                    }
                    const width = 800;
                    const height = 800;

                    const left = (window.innerWidth - width) / 2;
                    const top = (window.innerHeight - height) / 2;

                    const windowFeatures =
                        "width=" +
                        width +
                        ",height=" +
                        height +
                        ",left=" +
                        left +
                        ",top=" +
                        top;

                    if (process.env.REACT_APP_FILE_STORAGE === "dell") {
                        const byteCharacters = atob(downloadLink?.data);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);

                        // Create a Blob from the binary data
                        const blob = new Blob([byteArray], {
                            type:
                                mimeTypes[downloadLink?.extension?.toLowerCase()] ||
                                "application/octet-stream" // default to octet-stream if unknown
                        });

                        downloadLink = URL.createObjectURL(blob);
                    }
                    window.open(downloadLink, "_blank", windowFeatures);
                } else {
                    errorNotification("Unable to open document");
                }
            } catch (error) {
                console.error("Error opening document:", error);
            }
        }
    };

    const handleDocumentFetch = () => {
        const hasViewPermission = isDocumentPermissionBased
            ? currentStage[0]?.documentPermissionGroups?.includes(
            "viewOthersDocument"
        ) && userAuth?.groupCodes?.includes("viewOthersDocument")
            : true;

        let itemsNotInFields = (allDocuments || []).filter(
            item =>
                item?.additionalInformation &&
                !fieldsDictionary[item?.additionalInformation]
        );

        if (itemsNotInFields?.length > 0) {
            dispatch(
                appendFieldsWithDetails({
                    isDetailRequired: true,
                    fieldCodes: itemsNotInFields?.map(fld => fld?.additionalInformation)
                })
            );
        }

        dispatch(
            fetchDocuments({
                identifier: !isValueEmpty(workflow?.fields?.renewalWorkflowIdentifier) ? activeTab === "current" ? workflow?.identifier : workflow?.fields?.renewalWorkflowIdentifier : workflow?.identifier,
                pageNumber: queryParams?.pageNumber || 1,
                pageSize: queryParams?.pageSize || 5,
                bucketCode: currentProduct?.code?.toLowerCase(),
                ...(isDocumentFetchStageWise ? {stageCode: activeStageCode} : {}),
                ...(hasViewPermission ? {} : {ownership: userAuth?.id})
            })
        ).then(response => {
            if (response?.status === 200) {
                if (viewType === "module") {
                    const merged = merge(
                        keyBy(documentsList, "id"),
                        keyBy(response?.data?.items || [], "id")
                    );
                    setDocumentsList(values(merged));
                } else {
                    setDocumentsList(allDocuments);
                }
            }
        });
    };

    const importingData = (file, groupCode) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(file);
            fileReader.onload = e => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, {type: "binary"});
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                resolve(ws);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
        promise.then(dataCells => {
            let mapping = getGroupMapping(groupCode).mappings;
            (mapping || []).forEach(mapData => {
                setImportedFields(oldObj => ({
                    ...oldObj,
                    [mapData.fieldCode]: dataCells[mapData.cell].v
                }));
            });
        });
    };

    const uploadDropZoneFiles = () => {
        count = 0;
        handleFileSelected(acceptedFilesList, "", false, "multiple");
    };

    const handleFileSelected = async (
        files,
        code,
        isFetchDocuments,
        type,
        event = "",
        fileGroupCode
    ) => {
        if (type === "multiple" && count === acceptedFilesList?.length) {
            setSelectedGroupList({});
            setAcceptedFilesList(null);
            setShowUploadModal(false);
            setDropZoneLoader(false);
            handleDocumentFetch();
            return;
        }
        setUploadingFile(null);
        let file;
        let groupCode;
        if (type === "single") {
            file = Array.prototype.slice.call(files)[0];

            const acceptedExtensions = acceptFileType
                .split(",")
                .map(ext => ext.trim().toLowerCase());

            const fileType = file?.type;
            let fileExtension = file?.name
                .slice(file?.name.lastIndexOf("."))
                .toLowerCase();

            if (fileType.startsWith("image/")) {
                fileExtension = "image/*";
            }

            if (
                !acceptedExtensions.includes(fileExtension) &&
                !acceptedExtensions.includes(fileType)
            ) {
                event.target.value = null;
                errorNotification(
                    `${file?.name}: File type must be ${acceptFileType}`,
                    "fit-content"
                );
                setUploadingFile(null);
                return;
            }

            if (file?.size > maxSizeOfFile) {
                event.target.value = null;
                errorNotification(
                    `${file?.name}: File size should be less than ${formatBytes(
                        maxSizeOfFile
                    )}`
                );
                setUploadingFile(null);
                return;
            } else {
                infoNotification("Uploading document...");
            }
        } else if (type === "multiple") {
            setDropZoneLoader(true);
            file = acceptedFilesList?.[count];
            code = file?.name?.replace(/[^a-zA-Z 0-9]/g, "") + "_" + uuidv4();
            groupCode =
                selectedGroupList?.[file?.name?.replace(/[^a-zA-Z 0-9]/g, "")] || "";
        }
        let result = await toBase64(file).catch(e => Error(e));
        if (result instanceof Error) {
            setDropZoneLoader(false);
            setUploadingFile(null);
            return;
        }

        if (result) {
            const reuploadDocument = documentsList.find(
                document => document?.code === code
            );
            setUploadingFile(code);
            let extension =
                extensionTypes.find(fld => file?.type?.split("/")[1] === fld?.mimeType)
                    ?.extension ||
                file?.type?.split("/")[1] ||
                file?.name?.split(".")?.[1];
            if (
                ["xlsx", "xls", "csv"].includes(extension) &&
                getGroupMapping(groupCode)
            ) {
                importingData(file, groupCode);
            }
            let payload = {
                bucketCode: currentProduct?.code?.toLowerCase(),
                identifier: workflow?.identifier,
                name: file.name,
                code: code,
                description: "",
                // versionNumber: "1",
                file: result.split(",")[1],
                extension: extension,
                isComplete: true,
                groupCode: reuploadDocument
                    ? reuploadDocument?.groupCode || ""
                    : groupCode || "",
                documentType: 1, // 0 -> Checklist, 1 -> Additional Document
                additionalInformation: reuploadDocument
                    ? reuploadDocument?.groupCode
                    : groupCode,
                stageCode: activeStageCode
            };
            dispatch(uploadDocument(payload)).then(res => {
                if (res?.status === 200) {
                    setUploadingFile(null);
                    successNotification("Document uploaded successfully.");
                    if (type === "multiple")
                        handleFileSelected(
                            acceptedFilesList,
                            "",
                            false,
                            "multiple",
                            count++
                        );
                    if (isFetchDocuments) {
                        handleDocumentFetch();
                    }
                    // if ((groupCode || fileGroupCode) === "AccountStatement") {
                    //   let params = {
                    //     file: result.split(",")[1],
                    //     maxPages: 1
                    //   };
                    //   dispatch(parseAccountStatement(params)).then(response => {
                    //     if (response) {
                    //       successNotification("Account Statement parsed successfully.");
                    //       let accountStatementData = response?.data;
                    //       let updatedValues = {
                    //         ...fieldsValues,
                    //         ...accountStatementData?.summary,
                    //         AccountStatement: JSON.stringify(
                    //           accountStatementData?.transactions
                    //         ),
                    //         gridFields: {
                    //           ...gridFieldsValues,
                    //           AccountStatement: accountStatementData?.transactions
                    //         }
                    //       };
                    //       window.saveMode = "save";
                    //       onSubmit(updatedValues);
                    //
                    //       window.saveMode = "null";
                    //     }
                    //   });
                    // }
                } else {
                    if (type === "multiple")
                        handleFileSelected(
                            acceptedFilesList,
                            "",
                            false,
                            "multiple",
                            count++
                        );
                    setUploadingFile(null);
                }
            });
        }
    };

    const handleDelete = (document, version) => {
        const id = document?.id;
        const code = document?.code;
        setDocumentLoader(true);

        const confirmMessage = version
            ? "Are you sure you want to delete this version of the document? This action is irreversible."
            : "Are you sure you want to delete this document? This action is irreversible, and the document will be permanently deleted.";

        if (window.confirm(confirmMessage)) {
            const deleteAction = version ? deleteDocumentByVersion : deleteDocument;
            const payload = version ? {id, version} : code;

            dispatch(deleteAction(payload))
                .then(response => {
                    if (response?.status === 200) {
                        setDocumentsList([]);
                    }
                    setDocumentLoader(false);
                })
                .catch(error => {
                    setDocumentLoader(false);
                });
        } else {
            setDocumentLoader(false);
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setAcceptedFilesList(acceptedFiles);
    }, []);

    let column = [
        {
            dataField: "name",
            text: "Name",
            // sort: true,
            // sortCaret: sortCaret,
            formatter: DocumentNameFormatter
        },
        {
            dataField: "stageCode",
            text: "Stage",
            // sort: true,
            // sortCaret: sortCaret,
            formatter: DocumentStageFormatter,
            formatExtraData: {
                stageList: currentProduct?.stages
            }
        },
        {
            dataField: "groupCode",
            text: "Group/Checklist",
            formatter: DocumentGroupFormatter,
            formatExtraData: {
                groupList: documentGroups,
                checkList: checkLisGroupsDictionary
            }
        },
        {
            dataField: "createdAt",
            text: "Created",
            // sort: true,
            // sortCaret: sortCaret,
            formatter: DocumentCreatedFormatter,
            formatExtraData: {
                allUsers: allUsers
            }
        },
        {
            dataField: "lastUpdated",
            text: "Updated",
            // sort: true,
            // sortCaret: sortCaret,
            formatter: DocumentLastUpdatedFormatter,
            formatExtraData: {
                allUsers: allUsers
            }
        },
        {
            dataField: "actions",
            text: "Actions",
            // sort: true,
            // sortCaret: sortCaret,
            formatter: DocumentActionFormatter,
            formatExtraData: {
                handleDownload: handleDownload,
                workflow: workflow,
                productCode: currentProduct?.code,
                handleDocumentFetch: handleDocumentFetch,
                handleFileSelected: handleFileSelected,
                uploadingFile: uploadingFile,
                setUploadingFile: setUploadingFile,
                acceptedFilesList: acceptedFilesList,
                isAllowUpload,
                acceptFileType: acceptFileType,
                maxSizeOfFile: maxSizeOfFile,
                handleDelete: handleDelete,
                userId: userAuth?.id,
                permissionsObject: permissionsObject,
                isWorkflowRoute: isWorkflowRoute,
                isEligibleForReUploadMyDocument: isEligibleForReUploadMyDocument,
                isEligibleForReUploadOthersDocument: isEligibleForReUploadOthersDocument,
                isDocumentPermissionBased: isDocumentPermissionBased,
                activeTab: activeTab,
            }
        }
    ];

    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: sizePerPageList,
        sizePerPage: queryParams?.pageSize,
        page: queryParams?.pageNumber,
        hideStartingDots: true,
        hideEndingDots: true
    };

    const handleChangeSelect = (option, filename) => {
        let payload = {[filename?.replace(/[^a-zA-Z 0-9]/g, "")]: option?.value};
        setSelectedGroupList({...selectedGroupList, ...payload});
    };

    const loadMore = () => {
        if (documentsList?.length >= 6) {
            setQueryParams({
                ...queryParams,
                pageNumber: queryParams?.pageNumber + 1
            });
        }
    };

    const handleCheckbox = async (document, ischeck) => {
        setViewType("multiView");
        setDocumentLoader(true);
        if (document?.versionCount) {
            const params = {
                versionCount: document?.versionCount,
                identifier: document?.id
            };
            const response = await dispatch(downloadDocumentVersion(params));

            if (response?.status === 200) {
                let downloadLink = response.data;
                let documentExtension = "";

                if (process.env.REACT_APP_FILE_STORAGE === "dell") {
                    const byteCharacters = atob(downloadLink?.data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    // Create a Blob from the binary data
                    const blob = new Blob([byteArray], {
                        type: "application/octet-stream"
                    });

                    documentExtension = downloadLink?.extension;
                    downloadLink = URL.createObjectURL(blob);
                } else {
                    documentExtension = downloadLink
                        ?.split("?")?.[0]
                        ?.split(".")
                        ?.reverse()?.[0];
                }

                if (ischeck) {
                    let tempSelectedDocuments = [
                        ...(selectedDocuments || []),
                        {
                            name: document.name,
                            documentId: document.id,
                            versionCount: document.versionCount,
                            expiresIn: 1,
                            code: document?.code,
                            documentType: document.type,
                            viewId: downloadLink,
                            viewExtension: documentExtension,
                            isChecked: ischeck
                        }
                    ];
                    setSelectedDocument(tempSelectedDocuments);
                    setDocumentLoader(false);
                } else {
                    let tempSelectedDocument = [...selectedDocuments];
                    let index = tempSelectedDocument.findIndex(
                        item => item.documentId === document.id
                    );
                    tempSelectedDocument[index].isChecked = false;
                    tempSelectedDocument.splice(index, 1);
                    setDocumentLoader(false);
                    if (tempSelectedDocument.length === 0) {
                        setViewType("module");
                    }
                    setSelectedDocument(tempSelectedDocument);
                }
            }
        }
    };

    const getDocumentTypeTitle = document => {
        let documentTypeTitle = "N/A";
        if (document.documentType === 0) {
            if (document?.additionalInformation?.includes("-")) {
                let index = document?.additionalInformation.indexOf("-");
                let additionalInformationChecklistCode = document?.additionalInformation.substring(
                    0,
                    index
                );
                let additionalInformationUniqueIdentifier = document?.additionalInformation.substring(
                    index + 1
                );

                if (additionalInformationChecklistCode) {
                    documentTypeTitle =
                        checkLisGroupsDictionary[additionalInformationChecklistCode]
                            ?.name ||
                        additionalInformationChecklistCode ||
                        "";
                }

                if (additionalInformationUniqueIdentifier) {
                    documentTypeTitle += ` (${additionalInformationUniqueIdentifier})`;
                }
            } else {
                documentTypeTitle =
                    checkLisGroupsDictionary[document?.additionalInformation]?.name ||
                    document?.additionalInformation ||
                    "N/A";
            }
        } else if (document.documentType === 2) {
            documentTypeTitle =
                fieldsDictionary[document.additionalInformation]?.name ||
                document?.additionalInformation ||
                "N/A";
        } else {
            documentTypeTitle =
                documentGroupsDictionary[document.additionalInformation]?.name ||
                document?.additionalInformation ||
                "N/A";
        }

        return documentTypeTitle || "N/A";
    };

    return (
        <Dialog
            bodyId="scrollableDiv"
            size={showUploadModal ? "lg" : "xl"}
            isCentered={false}
            show={show}
            onHide={onHide}
            modalTitle={
                <>
                    {showUploadModal
                        ? "Upload Documents"
                        : viewingDocumentId || viewType === "multiView"
                            ? "Preview"
                            : "Documents"}

                    {Number(totalCount) &&
                    !showUploadModal &&
                    !viewingDocumentId &&
                    viewType !== "multiView" ? (
                        <>
            <span className="ml-4 font-size-sm bg-light-primary text-primary p-2 rounded">
              Number Of Documents : {totalCount}
            </span>
                        </>
                    ) : null}

                    {isAllowUpload && !viewingDocumentId && viewType !== "multiView" ? (
                        <OverlayTrigger
                            overlay={<Tooltip id="upload-tooltip">Upload Document</Tooltip>}
                        >
            <span
                hidden={showUploadModal || !isEligibleForDocumentUpload}
                onClick={() => setShowUploadModal(true)}
                className="font-size-sm bg-primary text-white p-2 rounded ml-2 mr-2 cursor-pointer"
            >
              <span className="pr-1 pl-1">Upload</span>
            </span>
                        </OverlayTrigger>
                    ) : null}

                    {!viewingDocumentId && viewType !== "multiView" ? (
                        <div
                            className="btn-group"
                            role="group"
                            style={{position: "absolute", right: "25px"}}
                        >
                            <button
                                type="button"
                                onClick={() => setViewType("list")}
                                className={`btn btn-icon ${
                                    viewType === "list" ? "btn-primary" : "btn-outline-secondary"
                                }`}
                            >
                                <i className="fas fa-th-list"/>
                            </button>
                            <button
                                type="button"
                                onClick={() => setViewType("module")}
                                className={`btn btn-icon ${
                                    viewType === "module"
                                        ? "btn-primary"
                                        : "btn-outline-secondary"
                                }`}
                            >
                                <i className="fas fa-th"/>
                            </button>
                        </div>
                    ) : null}
                </>
            }
            isLoading={isLoading}
            modalBody={
                <div style={{maxHeight: "57vh"}}>
                    {showUploadModal ? (
                        <GenericFileUpload
                            groupList={documentGroups}
                            onDrop={onDrop}
                            acceptedFilesList={acceptedFilesList}
                            handleChangeSelect={handleChangeSelect}
                            type={"bulkUpload"}
                            acceptFileType={acceptFileType}
                            maxSizeOfFile={maxSizeOfFile}
                            isDocumentGroupRequired={isDocumentGroupRequired}
                        />
                    ) : !isValueEmpty(workflow?.fields?.renewalProductCode) && !isValueEmpty(workflow?.fields?.renewalWorkflowIdentifier)  ? (
                        <Tab.Container
                            activeKey={activeTab}
                            onSelect={(key) => {
                                setActiveTab(key);
                                setDocumentsList([]);
                            }}
                        >

                            <Nav
                                className="nav-tabs nav-tabs-primary flex-grow-1 px-10 mb-5"
                            >
                                {DocumentTabs.map((item, i) => {
                                    return (
                                        <Nav.Item key={i}>
                                            <Nav.Link eventKey={item.key}>
                                                <span className="nav-text font-weight-bolder">{item.name}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                            </Nav>

                            <Tab.Content>

                                {DocumentTabs.map((item, i) => {
                                    if (activeTab  === item?.key) {
                                        return (<Tab.Pane eventKey={item.key} key={i}>
                                            {(!documentsList?.length &&
                                                !allDocuments?.length &&
                                                !listLoading &&
                                                !showUploadModal) ? (
                                                <>
                                                    <img
                                                        className="h-180px w-100"
                                                        alt="No data found"
                                                        src={toAbsoluteUrl("/media/svg/illustrations/copy.svg")}
                                                    />
                                                    <p className="text-center my-3"> No Documents Found </p>
                                                </>
                                            ) : !showUploadModal ? (
                                                <>
                                                    {viewingDocumentId ? (
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "1fr 1fr",
                                                                gridColumnGap: "38px",
                                                            }}
                                                        >
                                                            <Preview
                                                                viewDocument={viewDocument}
                                                                viewingDocumentId={viewingDocumentId}
                                                                viewingDocumentExtension={viewingDocumentExtension}
                                                                viewType={viewType}
                                                                setIsDocumentOpening={setIsDocumentOpening}
                                                                handleOpenDocumentInNewTab={handleOpenDocumentInNewTab}
                                                                handleOpenDocumentInNewWindow={handleOpenDocumentInNewWindow}
                                                                documentVersion={documentVersion}
                                                            />
                                                        </div>
                                                    ) : viewType === "module" ? (
                                                        <Thumbnails
                                                            loadMore={loadMore}
                                                            documents={(documentsList || []).map((document) => ({
                                                                ...document,
                                                                groupName: getDocumentTypeTitle(document),
                                                            }))}
                                                            activeTab={activeTab}
                                                            isAllowUpload={isAllowUpload}
                                                            handleDownload={handleDownload}
                                                            handleOpenDocumentInNewTab={handleOpenDocumentInNewTab}
                                                            handleOpenDocumentInNewWindow={handleOpenDocumentInNewWindow}
                                                            isDocumentOpening={isDocumentOpening}
                                                            setOpenInTabOrWindow={setOpenInTabOrWindow}
                                                            openInTabOrWindow={openInTabOrWindow}
                                                            handleFileSelected={handleFileSelected}
                                                            uploadingFile={uploadingFile}
                                                            viewType={viewType}
                                                            workflow={workflow}
                                                            handleCheckbox={handleCheckbox}
                                                            acceptFileType={acceptFileType}
                                                            maxSizeOfFile={maxSizeOfFile}
                                                            handleDelete={handleDelete}
                                                            userId={userAuth?.id}
                                                            permissionsObject={permissionsObject}
                                                            isWorkflowRoute={isWorkflowRoute}
                                                            isEligibleForReUploadMyDocument={isEligibleForReUploadMyDocument}
                                                            isEligibleForReUploadOthersDocument={isEligibleForReUploadOthersDocument}
                                                            listLoading={listLoading}
                                                            totalCount={totalCount}
                                                            allUsers={allUsers}
                                                            documentLoader={documentLoader}
                                                        />
                                                    ) : viewType === "multiView" ? (
                                                        <Multiview
                                                            viewDocument={viewDocument}
                                                            viewingDocumentId={viewingDocumentId}
                                                            viewingDocumentExtension={viewingDocumentExtension}
                                                            selectedDocuments={selectedDocuments}
                                                            viewType={viewType}
                                                            loadMore={loadMore}
                                                            handleCheckbox={handleCheckbox}
                                                            documentsList={documentsList}
                                                            documentLoader={documentLoader}
                                                            handleOpenDocumentInNewTab={handleOpenDocumentInNewTab}
                                                            handleOpenDocumentInNewWindow={handleOpenDocumentInNewWindow}
                                                            groupList={documentGroups}
                                                            checkListDictionary={checkLisGroupsDictionary}
                                                        />
                                                    ) : (
                                                        <>
                                                            {listLoading ? (
                                                                <div className="text-center">
                                                                    <CircularProgress className="spinner-lg"/>
                                                                </div>
                                                            ) : (
                                                                <DataGrid
                                                                    data={allDocuments || []}
                                                                    columns={column}
                                                                    listLoading={listLoading}
                                                                    paginationOptions={paginationOptions}
                                                                    onTableParamsChange={getHandlerTableChange(setQueryParams)}
                                                                    type={0}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <GenericFileUpload
                                                    groupList={documentGroups}
                                                    onDrop={onDrop}
                                                    acceptedFilesList={acceptedFilesList}
                                                    handleChangeSelect={handleChangeSelect}
                                                    type={"bulkUpload"}
                                                    acceptFileType={acceptFileType}
                                                    maxSizeOfFile={maxSizeOfFile}
                                                    isDocumentGroupRequired={isDocumentGroupRequired}
                                                />
                                            )}
                                        </Tab.Pane>);
                                    }
                                    return null;
                                })}

                            </Tab.Content>
                        </Tab.Container>
                    ) : (
                        <>
                            {!documentsList?.length &&
                            !allDocuments?.length &&
                            !listLoading &&
                            !showUploadModal ? (
                                <>
                                    <img
                                        className="h-180px w-100"
                                        alt="No data found"
                                        src={toAbsoluteUrl("/media/svg/illustrations/copy.svg")}
                                    />
                                    <p className="text-center my-3"> No Documents Found </p>
                                </>
                            ) : !showUploadModal ? (
                                <>
                                    {viewingDocumentId ? (
                                        <div
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "1fr 1fr",
                                                gridColumnGap: "38px",
                                            }}
                                        >
                                            <Preview
                                                viewDocument={viewDocument}
                                                viewingDocumentId={viewingDocumentId}
                                                viewingDocumentExtension={viewingDocumentExtension}
                                                viewType={viewType}
                                                setIsDocumentOpening={setIsDocumentOpening}
                                                handleOpenDocumentInNewTab={handleOpenDocumentInNewTab}
                                                handleOpenDocumentInNewWindow={handleOpenDocumentInNewWindow}
                                                documentVersion={documentVersion}
                                            />
                                        </div>
                                    ) : viewType === "module" ? (
                                        <Thumbnails
                                            loadMore={loadMore}
                                            documents={(documentsList || []).map((document) => ({
                                                ...document,
                                                groupName: getDocumentTypeTitle(document),
                                            }))}
                                            isAllowUpload={isAllowUpload}
                                            handleDownload={handleDownload}
                                            handleOpenDocumentInNewTab={handleOpenDocumentInNewTab}
                                            handleOpenDocumentInNewWindow={handleOpenDocumentInNewWindow}
                                            isDocumentOpening={isDocumentOpening}
                                            setOpenInTabOrWindow={setOpenInTabOrWindow}
                                            openInTabOrWindow={openInTabOrWindow}
                                            handleFileSelected={handleFileSelected}
                                            uploadingFile={uploadingFile}
                                            viewType={viewType}
                                            workflow={workflow}
                                            handleCheckbox={handleCheckbox}
                                            acceptFileType={acceptFileType}
                                            maxSizeOfFile={maxSizeOfFile}
                                            handleDelete={handleDelete}
                                            userId={userAuth?.id}
                                            permissionsObject={permissionsObject}
                                            isWorkflowRoute={isWorkflowRoute}
                                            isEligibleForReUploadMyDocument={
                                                isEligibleForReUploadMyDocument
                                            }
                                            isEligibleForReUploadOthersDocument={
                                                isEligibleForReUploadOthersDocument
                                            }
                                            listLoading={listLoading}
                                            totalCount={totalCount}
                                            allUsers={allUsers}
                                            documentLoader={documentLoader}
                                        />
                                    ) : viewType === "multiView" ? (
                                        <Multiview
                                            viewDocument={viewDocument}
                                            viewingDocumentId={viewingDocumentId}
                                            viewingDocumentExtension={viewingDocumentExtension}
                                            selectedDocuments={selectedDocuments}
                                            viewType={viewType}
                                            loadMore={loadMore}
                                            handleCheckbox={handleCheckbox}
                                            documentsList={documentsList}
                                            documentLoader={documentLoader}
                                            handleOpenDocumentInNewTab={handleOpenDocumentInNewTab}
                                            handleOpenDocumentInNewWindow={handleOpenDocumentInNewWindow}
                                            groupList={documentGroups}
                                            checkListDictionary={checkLisGroupsDictionary}
                                        />
                                    ) : (
                                        <DataGrid
                                            data={allDocuments || []}
                                            columns={column}
                                            listLoading={listLoading}
                                            paginationOptions={paginationOptions}
                                            onTableParamsChange={getHandlerTableChange(setQueryParams)}
                                            type={0}
                                        />
                                    )}
                                </>
                            ) : null}
                        </>
                    )}
                </div>
            }
            modalBodyClasses={"text-justify overflow-auto document-body"}
            modalFooter={
                <>
                    <button
                        type="button"
                        disabled={dropZoneLoader}
                        className="btn btn-light"
                        onClick={onHide}
                    >
                        Close
                    </button>

                    {viewingDocumentId || viewType === "multiView" ? (
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={dropZoneLoader}
                            onClick={() => {
                                setViewingDocumentId(null);
                                setViewingDocumentExtension(null);
                                if (viewType === "multiView") {
                                    setSelectedDocument(null);
                                    setViewType("module");
                                }
                            }}
                        >
                            Back
                        </button>
                    ) : null}

                    {showUploadModal ? (
                        <>
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={dropZoneLoader}
                                onClick={() => {
                                    setSelectedGroupList({});
                                    setAcceptedFilesList(null);
                                    setShowUploadModal(false);
                                    handleDocumentFetch();
                                }}
                            >
                                Back
                            </button>

                            <button
                                type="button"
                                disabled={acceptedFilesList?.length === 0 || dropZoneLoader}
                                className={`btn btn-success ${
                                    dropZoneLoader ? "spinner spinner-white spinner-right" : ""
                                }`}
                                onClick={() => {
                                    if (
                                        isDocumentGroupRequired &&
                                        Object.values(selectedGroupList)?.length !==
                                        acceptedFilesList?.length
                                    ) {
                                        errorNotification("Group Is Required");
                                    } else {
                                        uploadDropZoneFiles();
                                    }
                                }}
                            >
                                Upload
                            </button>
                        </>
                    ) : null}
                </>
            }
        />
    );

}