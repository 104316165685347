import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Overlay } from "../..//controls/forms/Overlay";

const getFieldCSSClasses = (touched, errors, isHideValidations) => {
  const classes = ["form-control"];

  if (!isHideValidations) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }
  }

  return classes.join(" ");
};

const getValidMomentDate = (date, statementDateFormat = false, tags) => {
  let currentDate = date;
  const isDateFromDayTag = tags?.find(
    tag => tag === "dateFromDay" || tag?.code === "dateFromDay"
  );

  const isDateFromDayEffectiveTag = tags?.find(
      tag => tag === "dateFromDayEffective" || tag?.code === "dateFromDayEffective"
  );

  if (isDateFromDayTag) {
    const currentDate = moment();
    const lastDayOfMonth = currentDate
      .clone()
      .endOf("month")
      .date();

    if (date > lastDayOfMonth) {
      currentDate.date(lastDayOfMonth);
    } else {
      currentDate.date(date);
    }

    return currentDate.toDate();
  }

  if (isDateFromDayEffectiveTag) {
    const currentDate = moment();
    const lastDayOfMonth = currentDate.clone().endOf("month").date();

    // Check if the input date is greater than the last day of the current month
    if (date > lastDayOfMonth) {
      currentDate.date(lastDayOfMonth);
    } else if (date < currentDate.date()) {
      // If the input date is before the current date, move to the next month
      const nextMonth = currentDate.clone().add(1, "month");
      const lastDayOfNextMonth = nextMonth.clone().endOf("month").date();

      if (date > lastDayOfNextMonth) {
        nextMonth.date(lastDayOfNextMonth);
      } else {
        nextMonth.date(date);
      }
      return nextMonth.toDate();
    } else {
      // If the date is within this month and not before the current date
      currentDate.date(date);
    }

    return currentDate.toDate();
  }


  return moment(
    currentDate,
    !statementDateFormat ? moment.defaultFormat : "DD/MM/yyyy"
  )?.isValid()
    ? moment(
        currentDate,
        !statementDateFormat ? moment.defaultFormat : "DD/MM/yyyy"
      )?.toDate()
    : "";
};

const getDatesLimitations = tags => {
  let disallowPastDays =
    tags?.find(tag => tag?.disallowPastDays)?.disallowPastDays === "True" ||
    tags?.find(tag => tag === "disallowPastDays");
  let allowPastNDays =
    tags?.find(tag => tag?.LastNDays)?.LastNDays ||
    tags?.find(tag => tag?.lastNDays)?.lastNDays ||
    tags?.find(tag => tag === "lastNDays") ||
    tags?.find(tag => tag === "LastNDays");
  let disallowFutureDays =
    tags?.find(tag => tag?.disallowFutureDays)?.disallowFutureDays === "True" ||
    tags?.find(tag => tag === "disallowFutureDays");
  let allowFutureNDays =
    tags?.find(tag => tag?.NextNDays)?.NextNDays ||
    tags?.find(tag => tag?.nextNDays)?.nextNDays ||
    tags?.find(tag => tag === "nextNDays") ||
    tags?.find(tag => tag === "NextNDays");

  let limitations = {
    minDate: undefined,
    maxDate: undefined
  };

  if (disallowPastDays || allowPastNDays) {
    limitations["minDate"] = allowPastNDays
      ? moment()
          .subtract(allowPastNDays, "d")
          .toDate()
      : new Date();
    limitations["message"] = `Date should not be earlier than ${
      allowPastNDays ? allowPastNDays + " days" : "current date"
    }`;
  }

  if (disallowFutureDays || allowFutureNDays) {
    limitations["maxDate"] = allowFutureNDays
      ? moment()
          .add(allowFutureNDays, "d")
          .toDate()
      : new Date();
    limitations["message"] = `Date should not be greater than ${
      allowFutureNDays ? allowFutureNDays + " days" : "current date"
    }`;
  }

  return limitations;
};

const pickerTypeEvaluator = tags => {
  let isYearPicker = false;
  let statementDateFormat = false;

  (tags || []).forEach(tag => {
    if (tag?.code === "ShowYearsOnly") {
      isYearPicker = true;
    }
    if (tag === "statementDateFormat") {
      statementDateFormat = true;
    }
  });

  return {
    isYearPicker,
    statementDateFormat
  };
};

export function DatePickerField(props) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const { maxDate, minDate, message } = getDatesLimitations(props?.tags);
  const { isYearPicker, statementDateFormat } = pickerTypeEvaluator(
    props?.tags
  );
  return (
    <>
      {props.label && (
        <label>
          {props.label}{" "}
          {props.required ? <span className={"text-danger"}> *</span> : null}
        </label>
      )}
      {props.showDescription ? (
        <Overlay popoverContent={props.description}>
          <i
            className={
              "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
            }
          />
        </Overlay>
      ) : null}
      <DatePicker
        className={getFieldCSSClasses(
          touched[field.name],
          errors[field.name],
          props.isHideValidations
        )}
        {...field}
        {...props}
        minDate={minDate}
        maxDate={maxDate}
        disabled={props.isReadOnly || props.disabled}
        showYearDropdown
        value={
          (field?.value &&
            getValidMomentDate(
              field?.value,
              statementDateFormat,
              props?.tags
            )) ||
          (moment(props.defaultValue).isValid()
            ? props?.defaultValue && new Date(props?.defaultValue)
            : null) ||
          null
        }
        selected={
          (field?.value &&
            getValidMomentDate(
              field.value,
              statementDateFormat,
              props?.tags,
              field
            )) ||
          (moment(props.defaultValue).isValid()
            ? props?.defaultValue && new Date(props?.defaultValue)
            : null) ||
          null
        }
        showYearPicker={isYearPicker}
        onChange={val => {
          if (!val) {
            setFieldValue(field.name, null);
          } else {
            if (val && val?.getFullYear()?.toString()?.length < 5) {
              let difference =
                val?.getTimezoneOffset() - new Date().getTimezoneOffset();
              if (difference === 0) {
                setFieldValue(field.name, val);
              } else {
                let newVal = moment(val)
                  .startOf("day")
                  .toDate();
                if (difference > 0) {
                  let newDateObj = moment(newVal)
                    .add(difference, "m")
                    .toDate();
                  setFieldValue(field.name, newDateObj);
                } else {
                  let newDateObj = moment(newVal)
                    .add(Math.abs(difference), "m")
                    .toDate();
                  setFieldValue(field.name, newDateObj);
                }
              }
            }
          }
        }}
      />
      {!props.isHideValidations ? (
        <>
          {errors[field.name] && touched[field.name] ? (
            <div className="invalid-feedback d-block">
              {errors[field.name].toString()}
            </div>
          ) : message ? (
            <div className="invalid-feedback d-block text-warning">
              <b>{message}</b>
            </div>
          ) : (
            <div className="invalid-feedback d-block text-muted">
              Format: <b>{props.dateFormat.toLowerCase()}</b>
            </div>
          )}
        </>
      ) : null}
    </>
  );
}
