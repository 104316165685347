import {
  adviceTypes,
  callStatusEnums,
  createdDiscrepancyStatus,
  createdAdviceStatus,
  trailActivityTypes,
  discrepancyTypes,
  setFlowsCurrentStatus,
  setFlowsExternalSystemStatus,
  workflowActivityType,
  workflowStatus,
  kiborTenors,
  kiborUnits,
  fieldLayout,
  adviceTrailActivityTypes,
  documentTypes,
  transactionTypes,
  externalSystem,
  dataSourceType
} from "../../_helpers/enums";

// import moment from "moment";
export const StatusCssClasses = [
  "success",
  "warning",
  "primary",
  "danger",
  "warning"
];
export const ChecklistStatusHelpers = [
  {
    icon: " fas icon-xl fa-hourglass-start text-primary",
    class: "primary",
    text: "Pending",
    buttonText: "Check"
  },
  {
    icon: " fas icon-xl fa-exclamation-triangle text-danger",
    class: "danger",
    text: "Match",
    buttonText: "Details"
  },
  {
    icon: " fas icon-xl fa-check text-success",
    class: "success",
    text: "No match",
    buttonText: "Re-check"
  }
];
export const DedupeStatusCssClasses = ["warning", "danger", "success"];
export const StatusTitles = [
  "Draft",
  "Awaiting Authorization",
  "Approved",
  "Rejected",
  "Pending"
];
export const AccountStatusTitles = ["Un-worked", "Worked", "Closed"];
export const AccountStatusCssClasses = ["danger", "success", "secondary"];

export const InterviewStatusCssClasses = {
  [callStatusEnums.Pending]: "warning",
  [callStatusEnums.InProgress]: "info",
  [callStatusEnums.Done]: "success",
  [callStatusEnums.Expire]: "dark",
  [callStatusEnums.Failed]: "danger"
};

export const InterviewStatusTitles = {
  [callStatusEnums.Pending]: "Pending",
  [callStatusEnums.InProgress]: "In Progress",
  [callStatusEnums.Done]: "Done",
  [callStatusEnums.Expire]: "Expire",
  [callStatusEnums.Failed]: "Failed"
};

export const ExternalSystemTitles = {
  [externalSystem.Cascade]: "Cascade",
  [externalSystem.EasyPaisa]: "Easy Paisa"
};

export const WorkflowStatusCssClasses = {
  [workflowStatus.InProgress]: "primary",
  [workflowStatus.Completed]: "success",
  [workflowStatus.Unassigned]: "info",
  [workflowStatus.AwaitingAuthentication]: "warning",
  [workflowStatus.Cancelled]: "danger",
  [workflowStatus.Rejected]: "danger",
  [workflowStatus.AwaitingPreChecksVerification]: "warning",
  [workflowStatus.Discrepant]: "warning",
  [workflowStatus.Withdrawn]: "danger",
  [workflowStatus.Hold]: "info",
  [workflowStatus.Freezed]: "danger",
  [workflowStatus.PostCompletionFailure]: "danger",
  [workflowStatus.ManuallyDisbursed]: "success"
};

export const WorkflowStatusTitles = {
  [workflowStatus.InProgress]: "In Progress",
  [workflowStatus.Completed]: "Completed",
  [workflowStatus.Unassigned]: "Unassigned",
  [workflowStatus.AwaitingAuthentication]: "Awaiting Authentication",
  [workflowStatus.Cancelled]: "Cancelled",
  [workflowStatus.Rejected]: "Rejected",
  [workflowStatus.AwaitingPreChecksVerification]:
    "Awaiting Pre Check Verification",
  // [workflowStatus.Discrepant]: "Discrepant",
  [workflowStatus.Withdrawn]: "Withdrawn",
  [workflowStatus.Hold]: "Held",
  [workflowStatus.Freezed]: "Frozen",
  [workflowStatus.PostCompletionFailure]: "Post-Completion Failure",
  [workflowStatus.ManuallyDisbursed]: "Manually Disbursed"
};

export const WorkflowStatusTitlesWithDiscrepant = {
  [workflowStatus.InProgress]: "In Progress",
  [workflowStatus.Completed]: "Completed",
  [workflowStatus.Unassigned]: "Unassigned",
  [workflowStatus.AwaitingAuthentication]: "Awaiting Authentication",
  [workflowStatus.Cancelled]: "Cancelled",
  [workflowStatus.Rejected]: "Rejected",
  [workflowStatus.AwaitingPreChecksVerification]:
    "Awaiting Pre Check Verification",
  [workflowStatus.Discrepant]: "Discrepant",
  [workflowStatus.Withdrawn]: "Withdrawn",
  [workflowStatus.Hold]: "Held",
  [workflowStatus.Freezed]: "Frozen",
  [workflowStatus.PostCompletionFailure]: "Post-Completion Failure",
  [workflowStatus.ManuallyDisbursed]: "Manually Disbursed"
};

export const WorkflowActivityTypesTitles = {
  [workflowActivityType.WorkflowInitiated]: "Workflow Initiated",
  [workflowActivityType.StageInitiated]: "Stage Initiated",
  [workflowActivityType.StageCompleted]: "Stage Completed",
  [workflowActivityType.StagePreceded]: "Stage Preceded",
  [workflowActivityType.BypassStageAuthentication]:
    "Bypass Stage Authentication",
  [workflowActivityType.ApproveStageAuthentication]:
    "Approved Stage Authentication",
  [workflowActivityType.RejectStageAuthentication]:
    "Discard Stage Authentication",
  [workflowActivityType.RecommendedApprove]: "Recommended",
  [workflowActivityType.RecommendedBypass]: "Recommended Bypass",
  [workflowActivityType.WorkflowCancelled]: "Workflow Cancelled",
  [workflowActivityType.WorkflowRejected]: "Workflow Rejected",
  [workflowActivityType.WorkflowRecalled]: "Workflow Recalled",
  [workflowActivityType.PreCheckApproved]: "Pre-check Approved",
  [workflowActivityType.PreCheckRejected]: "Pre-check Rejected",
  [workflowActivityType.Rectification]: "Rectification",
  [workflowActivityType.Escalated]: "Escalated",
  [workflowActivityType.Resumed]: "Resumed",
  [workflowActivityType.WorkflowReassigned]: "Workflow Reassigned",
  [workflowActivityType.WorkflowHold]: "Workflow Hold",
  [workflowActivityType.WorkflowFroze]: "Workflow Froze",
  [workflowActivityType.WorkflowAssigned]: "Workflow Assigned",
  [workflowActivityType.DraftStageAuthentication]: "Draft Stage Authentication",
  [workflowActivityType.AutoAssignment]: "Auto Assignment",
  [workflowActivityType.MaintainedPreviousAssignment]:
    "Maintained Previous User Assignment",
  [workflowActivityType.StageProceed]: "Stage Proceeded",
  [workflowActivityType.AuthenticationMoveBack]: "Authentication Preceded",
  [workflowActivityType.ExceptionApproved]: "Exception Approved",
  [workflowActivityType.ExceptionRejected]: "Exception Rejected",
  [workflowActivityType.DiscrepancyCreated]: "Discrepancy Created",
  [workflowActivityType.DiscrepancyRecreated]: "Discrepancy Recreated",
  [workflowActivityType.DiscrepancyDeleted]: "Discrepancy Deleted",
  [workflowActivityType.DiscrepancyResolved]: "Discrepancy Resolved",
  [workflowActivityType.WorkflowCompleted]: "Workflow Completed",
  [workflowActivityType.Notes]: "Workflow Comment Added",
  [workflowActivityType.ApprovedViaEmail]:
    "Approved Stage Authentication via Email",
  [workflowActivityType.RecommendedViaEmail]: "Recommended via Email"
};

export const WorkflowStatusActions = {
  CANCELLED: {
    name: "cancel",
    enum: 0
  },
  REJECTED: {
    name: "reject",
    enum: 1
  },
  RECALLED: {
    name: "recall",
    enum: 2
  },
  HOLD: {
    name: "hold",
    enum: 4
  },
  FREEZED: {
    name: "freeze",
    enum: 5
  },
  RESUME: {
    name: "resume",
    enum: 2
  },
  RESTORE: {
    name: "restore",
    enum: 2
  }
};

export const WorkflowStatusActionsTitles = {
  CANCELLED: "Cancel",
  REJECTED: "Reject",
  RECALLED: "Recall",
  HOLD: "Hold",
  FREEZED: "Freeze",
  RESUME: "Resume",
  RESTORE: "Restore"
};
export const defaultSorted = [{ dataField: "name", order: "asc" }];

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "200", value: 200 },
  { text: "500", value: 500 },
  { text: "1000", value: 1000 },
  { text: "1500", value: 1500 },
  { text: "2000", value: 2000 }
];

export const initialFilter = {
  name: undefined,
  code: undefined,
  pageNumber: 1,
  pageSize: 10,
  category: undefined
};

export const Status = {
  Draft: 0,
  SFA: 1,
  Approved: 2,
  Rejected: 3,
  Pending: 4,
  SFR: 5
};

export const EntityAction = {
  Added: 0,
  Edited: 1,
  Deleted: 2
};

export const Type = {
  Active: 0,
  Inactive: 1,
  Deleted: 2,
  AwaitingActivation: 3,
  Blocked: 4
};

export const accountsInitialFilter = {
  CardCustID: undefined,
  cardNo: undefined,
  product: "MCBCC",
  queue: undefined,
  customerIdentifier: undefined,
  customerName: undefined,
  strategy: undefined,
  dpdfrom: 0,
  dpdto: undefined,
  lastAssignmentDate: undefined,
  status: undefined,
  pageNumber: 1,
  pageSize: 10,
  paginationOverride: false
  // type: 0,
};

export const statementsInitialFilter = {
  pageNumber: 1,
  pageSize: 10
};

export const AccountStatus = {
  ToBeWorked: 0,
  Worked: 1
};

export const ActionStatus = ["Accepted", "Rejected"];
export const EntityActionCssClasses = ["primary", "warning", "danger"];
export const EntityActionTitles = ["Added", "Edited", "Deleted"];

export const SearchFilters = [
  // {
  //     name: "Type",
  //     code: "type",
  //     type: "select",
  //     options: [
  //       {
  //         title: "Approved",
  //         value: 0
  //       },
  //       {
  //         title: "Pending",
  //         value: 1
  //       },
  //     ]
  //   },
];

export const workflowsInitialFilter = {
  pageNumber: 1,
  pageSize: 10,
  paginationOverride: false
};

export const imageForDocument = [
  { extension: "doc", image: "/media/svg/files/doc-custom.svg" },
  { extension: "docx", image: "/media/svg/files/doc-custom.svg" },

  { extension: "ppt", image: "/media/svg/files/ppt-custom.svg" },
  { extension: "pptx", image: "/media/svg/files/ppt-custom.svg" },

  { extension: "xlsx", image: "/media/svg/files/csv-custom.svg" },
  { extension: "xlx", image: "/media/svg/files/csv-custom.svg" },

  { extension: "pdf", image: "/media/svg/files/pdf-custom.svg" },

  { extension: "jpeg", image: "/media/svg/files/image-custom.svg" },
  { extension: "jpg", image: "/media/svg/files/image-custom.svg" },
  { extension: "svg", image: "/media/svg/files/image-custom.svg" },
  { extension: "png", image: "/media/svg/files/image-custom.svg" }
];

// Table pagination properties
export const productFactoryPaginationOptions = {
  custom: true,
  data: [],
  totalSize: 0,
  sizePerPageList,
  sizePerPage: 10,
  page: 1,
  hideStartingDots: true,
  hideEndingDots: true
};

export const EntityTypeCssClasses = [
  "primary",
  "warning",
  "danger",
  "success",
  "danger"
];

export const EntityTypeTitles = [
  "Active",
  "Inactive",
  "Deleted",
  "Awaiting Activation",
  "Blocked"
];

export const SetFlowsCurrentStatusCssClasses = {
  [setFlowsCurrentStatus.InProgress]: "primary",
  [setFlowsCurrentStatus.Completed]: "success",
  [setFlowsCurrentStatus.Failed]: "danger"
};

export const SetFlowsCurrentStatusCssClassesTitles = {
  [setFlowsCurrentStatus.InProgress]: "In Progress",
  [setFlowsCurrentStatus.Completed]: "Completed",
  [setFlowsCurrentStatus.Failed]: "Failed"
};

export const SetFlowsExternalStatusColors = {
  [setFlowsExternalSystemStatus.Pending]: "#ffa800",
  [setFlowsExternalSystemStatus.InProgress]: "#8950fc",
  [setFlowsExternalSystemStatus.Completed]: "#1bc5bd",
  [setFlowsExternalSystemStatus.Failed]: "#f64e60"
};

export const SetFlowsExternalStatusTitles = {
  [setFlowsExternalSystemStatus.Pending]: "Pending",
  [setFlowsExternalSystemStatus.InProgress]: "In Progress",
  [setFlowsExternalSystemStatus.Completed]: "Completed",
  [setFlowsExternalSystemStatus.Failed]: "Failed"
};
// d;--info:#8950fc;--warning:#ffa800;--danger:#f64e60

export const interviewInitialFilters = {
  pageNumber: 1,
  pageSize: 10,
  category: 0,
  status: "0",
  name: undefined,
  email: undefined,
  agent: undefined
  // callStatus: [
  //   callStatusEnums.Pending,
  //   callStatusEnums.InProgress,
  //   callStatusEnums.Done,
  //   callStatusEnums.Expire,
  //   callStatusEnums.BeforeScheduleTime
  // ],
  // fromDate: moment().format("DD-MM-YYYY"),
  // toDate: moment()
  //   .endOf("day")
  //   .format()
};

export const discrepancyTypesTitles = {
  [discrepancyTypes.General]: "General",
  [discrepancyTypes.Document]: "Document"
};

export const adviceTypesTitles = {
  [adviceTypes.General]: "General",
  [adviceTypes.Document]: "Document",
  [adviceTypes.Both]: "Both"
};

export const createdDiscrepancyStatusTitles = {
  [createdDiscrepancyStatus.Pending]: "Pending",
  [createdDiscrepancyStatus.Resolved]: "Resolved",
  [createdDiscrepancyStatus.Deleted]: "Deleted"
};

export const createdAdviceStatusTitles = {
  [createdAdviceStatus.Pending]: "Pending",
  [createdAdviceStatus.Resolved]: "Resolved",
  [createdAdviceStatus.Deleted]: "Deleted"
};

export const trailActivityTypesTitles = {
  [trailActivityTypes.Created]: "Created",
  [trailActivityTypes.Resolved]: "Resolved",
  [trailActivityTypes.Recreated]: "Recreated",
  [trailActivityTypes.Deleted]: "Deleted"
};

export const adviceTrailActivityTypesTitles = {
  [adviceTrailActivityTypes.Created]: "Created",
  [adviceTrailActivityTypes.Advised]: "Advised",
  [adviceTrailActivityTypes.Renewed]: "Renewed",
  [adviceTrailActivityTypes.Deleted]: "Deleted"
};

export const documentTypesTitles = {
  [documentTypes.CheckList]: "CheckList",
  [documentTypes.AdditionalDocument]: "Additional Document"
};

export const workflowFilterChips = [
  {
    code: "inProgress",
    name: "In Progress",
    filter: {
      workflowStatus: `${workflowStatus.InProgress}`
    },
    isActive: false
  },
  {
    code: "awaitingAuthentication",
    name: "Awaiting Authentication",
    filter: {
      workflowStatus: `${workflowStatus.AwaitingAuthentication}`
    },
    isActive: false
  },
  {
    code: "discrepant",
    name: "Discrepant",
    filter: {},
    isActive: false
  },
  {
    code: "advice",
    name: "Advice",
    filter: {
      adviceRequired: true
    },
    isActive: false
  },
  {
    code: "held",
    name: "Held",
    filter: {
      workflowStatus: `${workflowStatus.Hold}`
    },
    isActive: false
  }
];

export const enquiryFilterChips = [
  {
    code: "inProgress",
    name: "In Progress",
    filter: {
      workflowStatus: `${workflowStatus.InProgress}`
    },
    isActive: false
  },
  {
    code: "awaitingAuthentication",
    name: "Awaiting Authentication",
    filter: {
      workflowStatus: `${workflowStatus.AwaitingAuthentication}`
    },
    isActive: false
  },
  {
    code: "completed",
    name: "Completed",
    filter: {
      workflowStatus: `${workflowStatus.Completed}`
    },
    isActive: false
  },
  {
    code: "discrepant",
    name: "Discrepant",
    filter: {},
    isActive: false
  },
  {
    code: "held",
    name: "Held",
    filter: {
      workflowStatus: `${workflowStatus.Hold}`
    },
    isActive: false
  },
  {
    code: "frozen",
    name: "Frozen",
    filter: {
      workflowStatus: `${workflowStatus.Freezed}`
    },
    isActive: false
  },
  {
    code: "postCompletionFailure",
    name: "Post Completion Failure",
    filter: {
      workflowStatus: `${workflowStatus.PostCompletionFailure}`
    },
    isActive: false
  }
];

export const ratesByTenorAndUnit = {
  [`${kiborTenors.One}-${kiborUnits.Week}`]: "week1",
  [`${kiborTenors.Two}-${kiborUnits.Week}`]: "week2",
  [`${kiborTenors.One}-${kiborUnits.Month}`]: "month1",
  [`${kiborTenors.Three}-${kiborUnits.Month}`]: "month3",
  [`${kiborTenors.Six}-${kiborUnits.Month}`]: "month6",
  [`${kiborTenors.Nine}-${kiborUnits.Month}`]: "month9",
  [`${kiborTenors.One}-${kiborUnits.Year}`]: "year"
};

export const fieldLayoutTitles = {
  [fieldLayout.ColMd12]: "col-md-12",
  [fieldLayout.ColMd6]: "col-md-6",
  [fieldLayout.ColMd4]: "col-md-4",
  [fieldLayout.ColMd3]: "col-md-3"
};

export const fieldLayoutOptions = [
  { value: 0, length: 1 },
  { value: 1, length: 2 },
  { value: 2, length: 3 },
  { value: 3, length: 4 }
];

export const transactionTypesTitles = {
  [transactionTypes.LimitApproved]: "Limit Approved",
  [transactionTypes.Disbursement]: "Disbursement",
  [transactionTypes.Repayment]: "Repayment",
  [transactionTypes.Closure]: "Closure",
  [transactionTypes.Accrual]: "Accrual"
};

export const UIHelpers = {
  StatusCssClasses,
  StatusTitles,
  defaultSorted,
  sizePerPageList,
  initialFilter,
  Status,
  EntityAction,
  EntityActionCssClasses,
  EntityActionTitles,
  productFactoryPaginationOptions,
  SetFlowsCurrentStatusCssClasses,
  SetFlowsCurrentStatusCssClassesTitles,
  SetFlowsExternalStatusTitles,
  WorkflowStatusActions
};

export const StatusCodeColors = {
  success: "#1bc5bd",
  error: "#f64e60"
};

// Define a mapping of file extensions to MIME types
export const mimeTypes = {
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  bmp: "image/bmp",
  doc: "application/msword",
  docx:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx:
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  txt: "text/plain",
  pdf: "application/pdf",
  csv: "text/csv",
  msg: "application/vnd.ms-outlook",
  eml: "message/rfc822"
};

export const DataSourceTitles = {
  [dataSourceType.Cascade]: "Cascade",
  [dataSourceType.LoanSmart]: "LoanSmart"
};

export const DataSourceTitlesCssClasses = {
  [dataSourceType.Cascade]: "primary",
  [dataSourceType.LoanSmart]: "success"
};
