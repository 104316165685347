import * as requestFromServer from "./crud";
import { callTypes, slice } from "./slice";

const { actions } = slice;

export const fetchWorkflows = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getAllWorkflows(params)
    .then(response => {
      const workflows = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(actions.workflowsFetched({ totalCount, workflows }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflows";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchWorkflowById = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getWorkflowById(params)
    .then(response => {
      const workflow = response.data;
      dispatch(actions.workflowFetched({ workflow: { ...workflow } }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchPreviousDeviations = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPreviousDeviations(params)
    .then(response => {
      const data = response.data;
      dispatch(actions.previousDeviationsFetched(data));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchWorkflowStages = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.stages }));
  return requestFromServer
    .getWorkflowStages(params)
    .then(response => {
      const workflowStages = response.data;
      dispatch(
        actions.workflowStagesFetched({ workflowStages: workflowStages })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflow stages";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchWorkflowStageById = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.stages }));
  return requestFromServer
    .getWorkflowStageById(params)
    .then(response => {
      const workflowStage = response.data;
      dispatch(actions.workflowStageFetched({ workflowStage: workflowStage }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflow stage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createWorkflow = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createWorkflow(params)
    .then(response => {
      const workflow = response?.data || {};
      if (params.notSaveInStore) return response;
      dispatch(actions.createdWorkflowProduct({ workflow }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const workflowInitiateAndProceedWithDocument = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .workflowInitiateAndProceedWithDocument(params)
    .then(response => {
      const workflow = response?.data || {};
      if (params.notSaveInStore) return response;
      dispatch(actions.createdWorkflowProduct({ workflow }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const saveWorkflow = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .saveWorkflow(params)
    .then(response => {
      const workflow = response.data;
      if (!workflow.identifier)
        return new Error(
          "One or more errors occurred while validating workflow."
        );
      else {
        dispatch(actions.workflowValidated({ workflow }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't validate workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const validateWorkflow = params => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .saveValidateWorkflow(params)
        .then(response => {
            const workflow = response.data;
            if (!workflow.identifier)
                return new Error(
                    "One or more errors occurred while validating workflow."
                );
            else {
                dispatch(actions.workflowValidated({ workflow }));
            }
            return response;
        })
        .catch(error => {
            error.clientMessage = "Can't validate workflow";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            return error?.response;
        });
};

export const validateWorkflowComputedFields = params => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));

    return requestFromServer
        .validateWorkflow(params)
        .then(response => {
            if (response.status === 200) {
                // Backend validation succeeded
                const computedData = response?.data;
                dispatch(actions.workflowValidated({ computedData }));
                return computedData;
            } else {
                throw new Error("Workflow computation and validation failed.");
            }
        })
        .catch(error => {
            error.clientMessage = "Validation and computation didn't perform successfully";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            return error?.response;
        });
};

export const proceedWorkflow = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .proceedWorkflow(params)
    .then(response => {
      const workflow = response.data;
      dispatch(actions.workflowProceeded({ workflow }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't proceed workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const precedeWorkflow = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .precedeWorkflow(params)
    .then(response => {
      const workflow = response.data;
      dispatch(actions.workflowPreceded({ workflow }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't precede workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const statusUpdateWorkflow = params => dispatch => {
  // dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .statusUpdateWorkflow(params)
    .then(response => response)
    .catch(error => {
      error.clientMessage = "Can't update workflow status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const clearWorkFlowList = () => dispatch => {
  dispatch(actions.clearWorkflowList());
};

export const createException = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createException(params)
    .then(response => {
      const exception = response?.data;
      dispatch(actions.exceptionCreated({ exception }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const processException = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .approveRejectException(params)
    .then(response => {
      const exception = response.data;
      dispatch(actions.approveRejectException({ exception }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't approve/reject Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const removeException = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteException(params)
    .then(response => {
      const exception = response.data;
      dispatch(actions.exceptionDeleted({ exception }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearExceptions = () => dispatch => {
  dispatch(actions.clearExceptions());
};

export const processAuthentication = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .processAuthentication(params)
    .then(response => {
      const authenticationResponse = response.data;
      dispatch(actions.authenticationResponse({ authenticationResponse }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't approve/reject Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getDeletedGridRows = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDeletedGridRows(params)
    .then(response => {
      const res = response.data;
      dispatch(actions.deletedGridRows({ res }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't approve/reject Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reassignmentGroup = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .reassignmentGroup(params)
    .then(response => {
      const reassignmentResponse = response.data;
      dispatch(actions.reassignmentResponse({ reassignmentResponse }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't approve/reject Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reassignmentUser = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .reassignmentUser(params)
    .then(response => {
      const reassignmentResponse = response.data;
      dispatch(actions.reassignmentResponse({ reassignmentResponse }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't reassign workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const assignmentUser = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .assignmentUser(params)
    .then(response => {
      const assignmentResponse = response.data;
      dispatch(actions.assignmentResponse({ assignmentResponse }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't assign workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const uploadDocument = params => dispatch => {
  dispatch(actions.documentUploadStarted());
  return requestFromServer
    .uploadDocument(params)
    .then(response => {
      if (response.status === 200) dispatch(actions.documentUploadCompleted());
      else dispatch(actions.documentUploadFailed());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Document upload failed";
      dispatch(actions.documentUploadFailed());
    });
};

export const downloadDocument = params => dispatch => {
  return requestFromServer
    .downloadDocument(params)
    .then(response => {
      return response;
    })
    .catch(error => {
      error.clientMessage = "Document download failed";
    });
};

export const fetchDocuments = params => dispatch => {
  dispatch(actions.documentsFetchStarted());
  // dispatch(actions.documentUploadStarted());
  return requestFromServer
    .getDocumentByIdentifier(params)
    .then(response => {
      const documents = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(actions.allDocumentsFetched({ documents, totalCount }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Document upload failed";
      dispatch(actions.documentUploadFailed());
    });
};

export const downloadDocumentVersion = params => dispatch => {
  return requestFromServer
    .downloadVersionDocument(params)
    .then(response => {
      return response;
    })
    .catch(error => {
      error.clientMessage = "Document download failed";
    });
};

export const deleteDocument = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDocument(params)
    .then(response => {
      dispatch(actions.documentDelete(params));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Document delete failed";
    });
};

export const integrateWorkflow = params => dispatch => {
  dispatch(actions.loadingWorkflowIntegration());
  return requestFromServer
    .integrateWorkflow(params)
    .then(response => {
      if (response.status === 200)
        dispatch(
          actions.workflowIntegrated({
            integrationType: params.data.integrationType,
            data: response.data
          })
        );
      else throw Error("Failed to integrate workflow");
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't check fraud status";
      dispatch(actions.workflowIntegrationFailed());
      return error;
    });
};

export const updateWorkflowFormActiveStage = params => dispatch => {
  dispatch(actions.workflowFormActiveStageUpdated(params));
};

export const fetchChecklistStageWise = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getStageCheckList(params)
    .then(response => {
      const checkList = response?.data;
      dispatch(actions.checkListFetched({ checkList }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflows";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearCheckList = () => dispatch => {
  dispatch(actions.clearCheckList());
};

export const proceedCheckList = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.checkList }));
  return requestFromServer
    .processCheckList(params)
    .then(response => {
      const checkList = response.data;
      dispatch(actions.processCheckListResponse({ checkList }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't approve/reject Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchIndividualIntegrationData = params => dispatch => {
  dispatch(actions.loadingWorkflowIntegration());
  return requestFromServer
    .getIntegration(params)
    .then(response => {
      dispatch(
        actions.workflowIntegrated({
          integrationType: params.data.integrationType,
          data: response?.data
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDedupeCriteria = params => dispatch => {
    dispatch(actions.loadingDedupeCriteria());
    return requestFromServer
        .getDedupingCriteria(params)
        .then(response => {
            dispatch(
                actions.DedupeCriteriaMathches({dedupeCriteriaMatches: response?.data})
            );
            return response;
        })
        .catch(error => {
            error.clientMessage = "Can't fetch Exception";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const fetchIntegrationDataForPreCheck = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getIntegrationForPreCheck(params)
    .then(response => {
      dispatch(
        actions.precheckData({
          integrationType: params.data.integrationType,
          data: response?.data
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchApprovingAuthority = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getApprovingAuthority(params)
    .then(response => {
      dispatch(actions.approvingAuthorityFetched({ data: response?.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Approving Authority";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchTrail = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getWorkflowTrail(params)
    .then(response => {
      dispatch(actions.trailFetched({ data: response?.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Approving Authority";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setQuickPanelOpened = () => dispatch => {
  dispatch(actions.quickPanelOpened());
};

export const fetchAuthenticationsByStage = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.ShowExceptionsLoader());
  return requestFromServer
    .fetchAuthenticationsByStage(params)
    .then(response => {
      dispatch(actions.AuthenticationsByStageFetched(response?.data));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchExceptionsByAuthentication = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.ShowExceptionsLoader());
  return requestFromServer
    .fetchExceptionsByAuthentication(params)
    .then(response => {
      const exceptionsGroupByAuthentication = response?.data;
      dispatch(
        actions.exceptionsByAuthenticationFetched(
          exceptionsGroupByAuthentication
        )
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflows";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRejectedApplication = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRejectedApplication(params)
    .then(response => {
      dispatch(actions.rejectedApplication({ data: response?.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Rejected Applications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDiscrepancies = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.showDiscrepancyLoader());
  return requestFromServer
    .fetchDiscrepancies(params)
    .then(response => {
      const discrepancies = response?.data;
      dispatch(actions.discrepanciesFetched(discrepancies));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflows";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDiscrepancy = params => dispatch => {
  // dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .createDiscrepancy(params)
    .then(response => {
      const discrepancy = response?.data;
      dispatch(actions.discrepancyCreated({ discrepancy }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createLegacyDiscrepancy = params => dispatch => {
  // dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .createLegacyDiscrepancy(params)
    .then(response => {
      const discrepancy = response?.data;
      dispatch(actions.discrepancyCreated({ discrepancy }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateLegacyDiscrepancy = params => dispatch => {
  // dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .updateLegacyDiscrepancy(params)
    .then(response => {
      const discrepancy = response?.data;
      dispatch(actions.approveRejectDiscrepancy({ discrepancy }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const processDiscrepancy = params => dispatch => {
  // dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .approveRejectDiscrepancy(params)
    .then(response => {
      const discrepancy = response.data;
      dispatch(actions.approveRejectDiscrepancy({ discrepancy }));
      return response;
    })
    .catch(error => {
      console.log(error);
      error.clientMessage = "Can't approve/reject Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error;
    });
};

export const removeDiscrepancy = params => dispatch => {
  // dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .deleteDiscrepancy(params)
    .then(response => {
      const discrepancy = response.data;
      dispatch(actions.discrepancyDeleted({ discrepancy }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find workflow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error;
    });
};

export const clearDiscrepancies = () => dispatch => {
  dispatch(actions.clearDiscrepancies());
};

export const fetchExposureSheet = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getExposureSheet(params)
    .then(response => {
      // if response gives empty object than empty object assign to rest variable
      let { gridExposureSheets, ...rest } = response?.data || {};
      let exposureSheet = [{ ...rest }, ...(gridExposureSheets || [])];
      dispatch(actions.exposureSheetFetched({ exposureSheet }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch exposure sheet";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createExposureSheet = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createExposureSheet(params)
    .then(response => {
      // if response gives empty object than empty object assign to rest variable
      let { gridExposureSheets, ...rest } = response?.data || {};
      let exposureSheet = [{ ...rest }, ...(gridExposureSheets || [])];
      dispatch(actions.exposureSheetCreated({ exposureSheet }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create exposure sheet";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateExposureSheet = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateExposureSheet(params)
    .then(response => {
      // if response gives empty object than empty object assign to rest variable
      let { gridExposureSheets, ...rest } = response?.data || {};
      let exposureSheet = [{ ...rest }, ...(gridExposureSheets || [])];

      dispatch(actions.exposureSheetUpdated({ exposureSheet }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update exposure sheet";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDisbursementFile = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDisbursementFile(params)
    .then(response => {
      const disbursedFile = response?.data;
      dispatch(actions.disbursementFileCreated({ disbursedFile }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create exposure sheet";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createUserRectification = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRectification(params)
    .then(response => {
      // const userRectification = response?.config?.data;
      dispatch(actions.rectificationCreated());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update User";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchPaymentPlan = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchPaymentPlan(params)
    .then(response => {
      const paymentPlan = response?.data?.yearlyRentalInstallement || [];
      dispatch(actions.paymentPlanFetched({ paymentPlan }));
      return response?.data;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch payment plan";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchInstallmentPlan = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchInstallmentPlan(params)
    .then(response => {
      const installmentPlan = response?.data || {};
      console.log(installmentPlan);
      dispatch(actions.installmentPlanFetched({ installmentPlan }));
      return response?.data;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch installment plan";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCustomerFromCNIC = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCustomerFromCNIC(params)
    .then(response => {
      // const entities = response?.data?.customers;
      dispatch(actions.customerFetchedFromCNIC());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch customer";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createCustomer = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCustomer(queryParams)
    .then(response => {
      const { customer } = response?.data || {};
      dispatch(actions.customerCreated({ customer }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const updateCustomer = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(queryParams)
    .then(response => {
      dispatch(actions.customerUpdated());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const deleteCustomer = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(queryParams)
    .then(response => {
      dispatch(actions.customerDeleted());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const fetchAllCustomers = params => dispatch => {
  const orderingParams = params.paginationOverride
    ? { orderBy: "order", orderType: 1 }
    : {};
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllCustomers({ ...params, ...orderingParams })
    .then(response => {

      const { items: customers, paging } = response?.data;
      let totalCount = paging?.totalItems || customers?.length;
      dispatch(actions.allCustomersFetched({ customers, paging, totalCount }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const generateDataCheckFile = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .generateDataCheckFile(params)
    .then(response => {
      dispatch(actions.dataCheckFileGenerated());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't generate data check file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createStageDiscrepancy = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createStageDiscrepancy(params)
    .then(response => {
      const discrepancy = response?.data;
      dispatch(actions.stageDiscrepancyCreated({ discrepancy }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create Discrepancy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateStageDiscrepancy = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStageDiscrepancy(params)
    .then(response => {
      const discrepancy = response?.data;
      dispatch(actions.stageDiscrepancyUpdated({ discrepancy }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update Discrepancy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAllDiscrepancies = params => dispatch => {
  const orderingParams = params.paginationOverride
    ? { orderBy: "order", orderType: 1 }
    : {};
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDiscrepancies({ ...params, ...orderingParams })
    .then(response => {
      const { items: discrepancies, paging } = response?.data;
      let totalCount = paging?.totalItems || discrepancies?.length;

      dispatch(
        actions.allDiscrepanciesFetched({ discrepancies, paging, totalCount })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Discrepancies";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllStageDiscrepancies = params => dispatch => {
  const orderingParams = params.paginationOverride
    ? { orderBy: "order", orderType: 1 }
    : {};
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllStageDiscrepancies({ ...params, ...orderingParams })
    .then(response => {
      const { items: discrepancies, paging } = response?.data;
      let totalCount = paging?.totalItems || discrepancies?.length;

      dispatch(
        actions.allStageDiscrepanciesFetched({
          discrepancies,
          paging,
          totalCount
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Stage Discrepancies";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllAdvices = params => dispatch => {
  const orderingParams = params.paginationOverride
    ? { orderBy: "order", orderType: 1 }
    : {};
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAdvices({ ...params, ...orderingParams })
    .then(response => {
      const { items: advices, paging } = response?.data;
      let totalCount = paging?.totalItems || advices?.length;
      dispatch(actions.allAdvicesFetched({ advices, paging, totalCount }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch advices";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllStageAdvices = params => dispatch => {
  const orderingParams = params.paginationOverride
    ? { orderBy: "order", orderType: 1 }
    : {};
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllStageAdvices({ ...params, ...orderingParams })
    .then(response => {
      const { items: advices, paging } = response?.data;
      let totalCount = paging?.totalItems || advices?.length;

      dispatch(
        actions.allStageAdvicesFetched({
          advices,
          paging,
          totalCount
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stage Advices";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createAdvice = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAdvice(params)
    .then(response => {
      const advice = response?.data;
      dispatch(actions.adviceCreated({ advice }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create Advice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateAdvice = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAdvice(params)
    .then(response => {
      const advices = response?.data;
      dispatch(actions.adviceUpdated({ advices }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update advice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchChildProductWorkflows = params => dispatch => {
  return requestFromServer
    .getAllWorkflows(params)
    .then(response => {
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflows";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const parseAccountStatement = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .parseAccountStatement(params)
    .then(response => {
      dispatch(actions.parseAccountStatementCompleted());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't parse Bank Statement";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const updateFormData = params => dispatch => {
  dispatch(actions.updateFormData(params));
};

export const clearDiscrepancy = params => dispatch => {
  dispatch(actions.clearWorkflowDiscrepancy());
};

export const clearAdvices = params => dispatch => {
  dispatch(actions.clearAdvices());
};

export const fetchTransactionsLogs = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .fetchTransactionsLogs(params)
    .then(response => {
      const transactionsLogs = response?.data?.data?.items;
      const totalCount = response.data?.data?.paging?.totalItems;

      dispatch(
        actions.transactionsLogsFetched({ totalCount, transactionsLogs })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't fetch transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearWorkFlow = () => dispatch => {
  dispatch(actions.clearWorkflow());
};

export const fetchCreditInquiry = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .fetchCreditInquiry(params)
    .then(response => {
      const creditSummary = response?.data?.data || {};
      dispatch(actions.CreditSummaryFetched({ creditSummary }));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch summary";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteDynamicFormGridRow = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteDynamicFormGridRow(params)
    .then(response => {
      const res = response.data;
      dispatch(actions.deleteDynamicGridRows({ res }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete grid row";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const runScreeningChecks = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .runScreeningChecks(params)
    .then(response => {
      const screeningResponse = response?.data;
      dispatch(
        actions.screeningChecksPerformed({
          [params.screenCode]: screeningResponse
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch transactions";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchScreeningChecksResult = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .fetchScreeningChecksResult(params)
    .then(response => {
      const screeningResponse = response?.data;
      dispatch(
        actions.screeningChecksPerformed({
          [params.screeningCode]: screeningResponse
        })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't fetch transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAccountStatement = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchAccountStatement(params)
    .then(response => {
      const statements = response?.data || {};
      dispatch(actions.fetchAccountStatement({ statements }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't Fetch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMetaData = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchMetaData(params)
    .then(response => {
      const data = response?.data || {};
      dispatch(actions.fetchMetaData({ data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't Fetch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearStatementTransactions = params => dispatch => {
  dispatch(actions.clearStatementTransactions());
};

export const updateAccountStatements = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTransactions(params)
    .then(response => {
      const updatedData = response?.data || {};
      dispatch(actions.updateAccountStatements({ updatedData }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't Fetch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const proceedCheckListWithAction = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.checkList }));
  return requestFromServer
    .processChecklistWithAction(params?.payload, params?.paramsPayload)
    .then(response => {
      const checkList = response?.data;
      const workflow = response?.data?.workflow;
      dispatch(actions.proceedCheckListWithAction({ checkList }));
      dispatch(actions.workflowFetched({ workflow: { ...workflow } }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't approve/reject Exception";
      dispatch(actions.catchError({ error, callType: callTypes.checkList }));
    });
};

export const mailApprovalPasswordAction = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.mailApprovalPassword(params).then(response => {
    const data = response?.data;
    dispatch(actions.mailApprovalPassword({ data }));
    return response;
  });
  // .catch(error => {
  //   error.clientMessage = "Can't get user";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};

export const fetchApprovingAuthorityLite = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getApprovingAuthorityLite(params)
    .then(response => {
      dispatch(actions.approvingAuthorityLite({ data: response?.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Approving Authority";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchLatestStageAuthentication = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.ShowExceptionsLoader());
  return requestFromServer
    .getLatestStageAuthentication(params)
    .then(response => {
      dispatch(actions.latestStageAuthentication(response?.data));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Authentication ";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchLatestStageExceptions = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.ShowExceptionsLoader());
  return requestFromServer
    .getLatestStageExceptions(params)
    .then(response => {
      const exceptionsGroupByAuthentication = response?.data;
      dispatch(actions.latestStageExceptions(exceptionsGroupByAuthentication));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Exception";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearAllWorkflowState = params => dispatch => {
  dispatch(actions.clearAllWorkflowState());
};

export const fetchRepricedPortfolios = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRepricedPortfolios(params)
    .then(response => {
      let totalCount = response?.data?.length;
      dispatch(
        actions.repricedPortfolios({ data: response?.data, totalCount })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Repriced Portfolios";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchRepricing = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRepricing(params)
    .then(response => {
      let totalCount = response?.data?.length;
      dispatch(actions.repricing({ data: response?.data, totalCount }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Repricing";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchBalloonPortfolios = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBalloonPortfolios(params)
    .then(response => {
      dispatch(actions.balloonPortfolios({ data: response?.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Balloon Portfolios";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchBalloon = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBalloon(params)
    .then(response => {
      dispatch(actions.balloon({ data: response?.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Balloon";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addDocumentComments = params => dispatch => {
  return requestFromServer
    .documentComments(params)
    .then(response => {
      return response;
    })
    .catch(error => {
      error.clientMessage = "Failed to add comment";
    });
};

export const fetchDocumentComments = params => dispatch => {
  return requestFromServer
    .getDocumentComments(params)
    .then(response => {
      return response;
    })
    .catch(error => {
      error.clientMessage = "Failed to fetch";
    });
};

export const addNotesWorkflow = params => dispatch => {
  return requestFromServer
    .addNotesAgainstWorkflow(params)
    .then(response => {
      return response;
    })
    .catch(error => {
      error.clientMessage = "Failed to add comment";
    });
};

export const fetchRetryPostCompletionFailure = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchRetryCompletedFailed(params)
    .then(response => {
      dispatch(actions.postCompletionFailure(response?.data));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch Balloon";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDocumentByVersion = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDocumentByVersion(params)
    .then(response => {
      dispatch(actions.documentDelete(params));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Document delete failed";
    });
};

export const fetchManuallyDisbursement = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchManuallyDisbursed(params)
    .then(response => {
      dispatch(actions.manuallyDisbursement(response?.data));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch manually disbursement";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMasterEnquiryWorkflows = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMasterEnquiryWorkflows(params)
    .then(response => {
      const workflows = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(
        actions.masterEnquiryWorkflowsFetched({ totalCount, workflows })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch workflows";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearCustomers = params => dispatch => {
    dispatch(actions.clearCustomers());
};
